
const GIVETIME_CLASS = "give-time";

const Givetime = () => {
    return (
      <section className="give-time-main">
        <div className="container">
          <div className={`${GIVETIME_CLASS}`}>
            <div className={`${GIVETIME_CLASS}__title`}>
              <h3>Give Time</h3>
            </div>
            <div className={`${GIVETIME_CLASS}__inner-sec`}>
              <div className={`${GIVETIME_CLASS}__info`}>
                <p>
                  Taking care of our mental health isn’t always easy, but it’s
                  so important. We all experience moments when our thoughts feel
                  overwhelming, and that’s perfectly okay, it’s part of being
                  human. Sometimes, a little comfort can go a long way. That’s
                  why we’re passionate about clothes that make you feel good.
                  Whether it’s the softness of the fabric, the design, or the
                  color, the right piece can bring a touch of joy on even the
                  toughest days. We’re here for those who overthink, doubt, and
                  seek peace—anyone navigating their own inner world. And, we
                  hope our collection offers you a bit of solace and ease.
                  #FreshnessWornEveryday
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
};

export default Givetime;