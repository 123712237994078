export const DRAWER_TYPES = Object.freeze({
    cart: 'cart',
    navigation: 'navigation',
    filter: 'filter',
    sort: 'sort'
});

export const PINCODE_ERRORS = Object.freeze({
    invalid: 'Please enter valid pincode',
    out_of_servicearea: 'Pincode not available for delivery',
});

export const LOGIN_TYPES = Object.freeze({
    sms: 'sms',
    email: 'email'
});

export const INPUT_TYPES = Object.freeze({
    otp: 'otp',
    email: 'email',
    phone: 'phone'
});

export const LOADER_TIMEOUT = 700;