import ImageWrapper from "../../Image/ImageWrapper";
import { connect } from 'react-redux';

function SizeChart(props) {
  const BLOCK_CLASS = "size-chart";

  const getSizeChartName = () => {
    let productCategories = props?.product?.productDetails?.categories;
    let productName = props?.product?.productDetails?.name;
    if (productCategories) {
      if (productCategories.includes("Dresses") || productCategories.includes("Kurtis")) {
        if (productName.includes("Kimono") || productName.includes("Wrap")) {
          return 'wrap_pants_wrap_skirts_kimono';
        } else {
          return 'dresses_kurtis';
        }
      } else if (productCategories.includes("Pants") || productCategories.includes("Skirts")){
        if (productName.includes("Wrap")) {
          return 'wrap_pants_wrap_skirts_kimono';
        } else {
          return 'pants';
        }
      } else if (productCategories.includes("Tops")){
        return 'tops';
      } else if (productCategories.includes("Co Ords")){
        return 'co-ords';
      }
    }
  };

  return (
    <div className={`${BLOCK_CLASS}`}>
      <h3>Size Chart</h3>

      <ImageWrapper
            name={getSizeChartName()}
            alt=""
            classes={`${BLOCK_CLASS}__image`}
            srcType={"internal"}
          ></ImageWrapper>

    </div>
  );
}

function mapStateToProps(state, ownProps) {
  const { product } = state;
  return { product }
}

export default connect(mapStateToProps)(SizeChart);
