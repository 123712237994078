import ImageWrapper from "../../components/Image/ImageWrapper";
import { useEffect } from "react";

export default function Contact(props) {
  const BLOCK_CLASS = "contact-us";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={`${BLOCK_CLASS}`}>
      <div className={`${BLOCK_CLASS}__banner`}>
        <h3>Get in touch</h3>
      </div>

      <div className={`${BLOCK_CLASS}__details-wrapper`}>
        <div>
          <ImageWrapper name="shipping" alt="Address icon"></ImageWrapper>
          <h4>Shipping Address</h4>
          <p className={`${BLOCK_CLASS}__text`}>
            Nilam India E-203 Doon Traflagar, Near IT Park Dehradun - 248001
          </p>
        </div>

        <div>
          <ImageWrapper name="order" alt="Orders icon"></ImageWrapper>
          <h4>For Order related queries</h4>
          <p className={`${BLOCK_CLASS}__text`}>customercare@nilamind.com</p>
        </div>

        <div>
          <ImageWrapper name="customer-care" alt="Customer care icon"></ImageWrapper>
          <h4>For Customer Care</h4>
          <p className={`${BLOCK_CLASS}__text`}>
            +91-8979733011<br></br>
            Mon - Sat: 10 am to 6 pm (IST)
          </p>
        </div>
      </div>

      <div className={`${BLOCK_CLASS}__disclaimer`}>
        <b>Note : </b>
        <p>
          Nilam does not send any direct email for collaboration/paid
          partnership to any person/content creator/customer, nor call/message
          to ask for bank details, OTP or any other personal sensitive
          information.
        </p>

        <p>
          Nilam shall in no way be held responsible for any loss, liability or
          obligation, nor does it assume any responsibility or liability for
          action of any third party.
        </p>
      </div>
    </div>
  );
}
