import React from "react";
import ImageWrapper from "../Image/ImageWrapper";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import {
  addToWishlist,
  removeFromWishlist,
  togglePlaceOrderModal
} from "../../store/actions";

const RecommendCard = (props) => {
  const wishlist = useSelector((state) => state.wishlist);
  const isUserLoggedIn = useSelector((state) => state.isUserLoggedIn);
  const dispatch = useDispatch();

  const isInWishlist = (sku) => {
    let currentDisplayedProduct = wishlist?.items?.filter((item) => {
      if (item.variant_sku == sku) {
        return item;
      }
    });

    return currentDisplayedProduct?.length > 0 ? true : false;
  }

  const onWishlistClick = (sku) => {
    if (!isUserLoggedIn) {
      dispatch(togglePlaceOrderModal({ type: "login" }));
      return
    }

    if (isInWishlist(sku)) {
      dispatch(removeFromWishlist(sku));
    } else {
      dispatch(addToWishlist(sku));
    }
  }

  return (
    <div className="recommend-inner">
      <p>Recommendations</p>
      <div className="recommend-main">
        {props.products.map((product) => {
          return (
            <div className="recommend-card">
              <figure className="recommend-images">
                <Link to={`/product/${product.variant_sku}`}>
                  <ImageWrapper
                    name="clothing-img1"
                    alt="clothing-img1"
                    externalUrl={product.primary_image}
                  />
                </Link>
                <figcaption>
                  <Link to={`/product/${product.variant_sku}`}>
                    <span>{product.name}</span>
                  </Link>
                  <button
                    onClick={() => {
                      onWishlistClick(product.variant_sku);
                    }}
                  >
                    <ImageWrapper
                      name={
                        isInWishlist(product.variant_sku)
                          ? "in-wishlist"
                          : "wishlist"
                      }
                      alt="Wishlist button"
                    />
                  </button>
                </figcaption>
              </figure>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RecommendCard;
