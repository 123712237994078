import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { getPaymentStatus } from "../../store/actions";
import ImageWrapper from "../../components/Image/ImageWrapper";

const BLOCK_CLASS = "payment-verification";
const PaymentVerification = (props) => {
  const dispatch = useDispatch();
  const [paymentStatus, setPaymentState] = useState(null);

  useEffect(() => {
    if (window.location.search !== "" && window.location.search.includes('transaction_id')) {
      let transactionId = window.location.search.split("=")[1];
      dispatch(getPaymentStatus(transactionId));
    }
  }, []);

  useEffect(() => {
    if (props.isPaymentSuccess !== null) {
      if (props.isPaymentSuccess) {
        setPaymentState("Success");
      } else {
        setPaymentState("Failure");
      }
    }
  }, [props.isPaymentSuccess]);

  return (
    <div className="payment-verification">
      {paymentStatus == null ? (
        <>
          <h2 className={`${BLOCK_CLASS}__headline`}>Pending confirmation</h2>
        </>
      ) : (
        <>
          {paymentStatus == "Success" ? (
            <ImageWrapper
              name="successful-purchase"
              classes={`${BLOCK_CLASS}__success-image`}
            />
          ) : (
            <ImageWrapper
              name="unsuccessful-purchase"
              classes={`${BLOCK_CLASS}__success-image`}
            />
          )}
          <h2 className={`${BLOCK_CLASS}__headline`}>
            Payment {paymentStatus}
          </h2>
          <p className={`${BLOCK_CLASS}__subtext`}>
            Transaction ID: {window.location?.search?.split("=")[1]}
          </p>
        </>
      )}
    </div>
  );
};

function mapStateToProps(state, ownProps) {
  const { paymentURL, isPaymentSuccess } = state;
  return { paymentURL, isPaymentSuccess };
}

export default connect(mapStateToProps)(PaymentVerification);
