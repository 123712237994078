import { useEffect, useState } from "react";
import Login from "../../Login/Login";
import { Button } from "antd";
import Address from "../../Address/Address";
import CartSummary from "../../CartSummary/CartSummary";
import { useDispatch, connect } from "react-redux";
import { checkout, showToastMessage } from "../../../store/actions";

const PlaceOrder = (props) => {
  const BLOCK_CLASS = "modal-wrapper";
  const ADDRESS_CLASS = "address-box";
  
  const dispatch = useDispatch();
  const [selectedAddress, selectCurrentAddress] = useState(null);
  const [shouldPlaceOrderBtnBeVisible, setEditModeState] = useState(true);

  useEffect(()=> {
    if (props.addresses.length > 0) {
      let primaryAddress;
      if (props.addresses.length > 1) {
        primaryAddress = props.addresses.filter((address) => {
          return address.primary == true;
        });
      } else {
        primaryAddress = props.addresses;
      }
      setEditModeState(true);
      let address_id = primaryAddress[0]?.id;
      if (address_id !== undefined) {
        selectCurrentAddress(address_id);
      }
    }
  }, [props.addresses]);

  const onAddressSelection = (e) => {
    // console.log(e);
    selectCurrentAddress(e);
    // setEditModeState(true);
  }

  const onEditAddressClick = (e) => {
    // console.log(e);
    setEditModeState(e);
  }

  const onPlaceOrder = () => {
    if (selectedAddress !== undefined && selectedAddress !== null) {
      dispatch(checkout({ address_id: selectedAddress, cart_id: props.cartDetails.id }));
    } else {
      dispatch(showToastMessage({ success: false, message: 'Please select delivery address' }))
    }
  }

  return (
    <div className={`${BLOCK_CLASS}`}>
      <CartSummary />

      {props.isUserLoggedIn ? (
        <div className={`${ADDRESS_CLASS}`}>
          <Address
            defaultAddressId={selectedAddress}
            onAddressSelection={onAddressSelection}
            onEditAddressClick={onEditAddressClick}
          />
          {shouldPlaceOrderBtnBeVisible ? (
            <Button
              className={`${ADDRESS_CLASS}__primary-cta`}
              onClick={onPlaceOrder}
              disabled={props.addresses.length == 0}
            >
              Place Order
            </Button>
          ) : null}
        </div>
      ) : (
        <Login />
      )}
    </div>
  );
}


function mapStateToProps(state, ownProps) {
  const { isUserLoggedIn, paymentURL, isPaymentInProgress, cartDetails, addresses } = state;
  return { isUserLoggedIn: isUserLoggedIn, paymentURL, isPaymentInProgress, cartDetails, addresses }
}

export default connect(mapStateToProps)(PlaceOrder)