import React, { useState, useEffect, useRef } from "react";
import Filter from "../../components/Filter/Filter";
import ImageWrapper from "../../components/Image/ImageWrapper";
import Card from "../../components/Card/Card";
import { useParams } from "react-router-dom";
import { getProducts, toggleMobileDrawerMenu, showAppLoader, hideAppLoader } from "../../store/actions";
import { useDispatch, connect } from "react-redux";
import { LOADER_TIMEOUT } from "../../utils/enumData";

const BLOCK_CLASS = "productlisting";

const items = [
  {
    key: '1',
    label: (
      <p className={`${BLOCK_CLASS}__dropdown-menu-item`}>
        Price - low to high
      </p>
    ),
  },
  {
    key: '2',
    label: (
      <p className={`${BLOCK_CLASS}__dropdown-menu-item`}>
        Price - high to low
      </p>
    ),
  },
  {
    key: '3',
    label: (
      <p className={`${BLOCK_CLASS}__dropdown-menu-item`}>
        Newly added
      </p>
    ),
  },
];

const ProductList = (props) => {
  const { category_name, subcategory_name } = useParams();
  const dispatch = useDispatch();
  const isMounted = useRef(false);
  
  useEffect(() => {
    dispatch(showAppLoader());
    const timer = setTimeout(() => {
      dispatch(hideAppLoader());
    }, LOADER_TIMEOUT);
    return () => clearTimeout(timer);
  }, []);

  useEffect(()=> {
    if (isMounted.current) {
      onLoad();
    }
  }, [subcategory_name]);

  useEffect(()=> {
    isMounted.current = true;
    onLoad();
  }, []);

  const onLoad = () => {
    window.scrollTo(0, 0);
    dispatch(getProducts([category_name, subcategory_name]));
    if (props.isMobileMenuOpen) {
      dispatch(toggleMobileDrawerMenu());
    }
  }

  return (
    <div className={`${BLOCK_CLASS}`}>
      {/* TODO: Provide viewport specific images instead of using multiple elements */}
      <ImageWrapper
        name="banner-btm-mobile"
        alt="freshness"
        classes={`${BLOCK_CLASS}__decor--mobile`}
      />
      <ImageWrapper
        name="banner-btm-desk"
        alt="freshness"
        classes={`${BLOCK_CLASS}__decor--desktop`}
      />
      <div className="container">
        {/* <div className={`${BLOCK_CLASS}__filter-wrapper`}>
          <button
            className={`${BLOCK_CLASS}__btn`}
            onClick={() => {
              setOpen(!open);
            }}
          >
            Filters
            <ImageWrapper
              name="filter-icon"
              alt=""
              classes={`${BLOCK_CLASS}__filter-icon`}
            />
          </button>

          <div className={`${BLOCK_CLASS}__filter-labels`}>
            <button
              className={`${BLOCK_CLASS}__btn ${BLOCK_CLASS}__filter-btn`}
            >
              Long
              <ImageWrapper
                name="close-icon"
                alt=""
                classes={`${BLOCK_CLASS}__close-filter-icon`}
              />
            </button>

            <button
              className={`${BLOCK_CLASS}__btn ${BLOCK_CLASS}__filter-btn`}
            >
              Long
              <ImageWrapper
                name="close-icon"
                alt=""
                classes={`${BLOCK_CLASS}__close-filter-icon`}
              />
            </button>

            <button
              className={`${BLOCK_CLASS}__btn ${BLOCK_CLASS}__filter-btn`}
            >
              Long
              <ImageWrapper
                name="close-icon"
                alt=""
                classes={`${BLOCK_CLASS}__close-filter-icon`}
              />
            </button>

            <button
              className={`${BLOCK_CLASS}__btn ${BLOCK_CLASS}__clear-filter-btn`}
            >
              Clear all
              <ImageWrapper
                name="close-icon"
                alt=""
                classes={`${BLOCK_CLASS}__filter-icon`}
              />
            </button>
          </div>

          <Dropdown
            menu={{ items }}
            placement="bottomRight"
            className={`${BLOCK_CLASS}__btn ${BLOCK_CLASS}__sort-btn`}
          >
            <Button>
              Sort
              <ImageWrapper
                name="sort-icon"
                alt=""
                classes={`${BLOCK_CLASS}__sort-icon`}
              />
            </Button>
          </Dropdown>
        </div> */}

        {props?.productsList?.map((product, i) => {
          return <Card data={product} key={i}/>;
        })}
      </div>
      {/* <Filter isOpen={open} /> */}
    </div>
  );
};

// export default ProductList;
function mapStateToProps(state, ownProps) {
  const { productsList, isMobileMenuOpen } = state;
  return { productsList: productsList, isMobileMenuOpen: isMobileMenuOpen }
}

export default connect(mapStateToProps)(ProductList)