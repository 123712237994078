import { Input, Steps, Collapse, Button } from "antd";
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

export default function AddressCard(props) {
  const BLOCK_CLASS = "address-box";

  let { id, full_name, flat_no, building_name, area, city, state, pincode, landmark, primary, mobile } = props.data;
  return (
    <div 
     className={`${BLOCK_CLASS}__list-item ${props.currentAddress == id ? ` ${BLOCK_CLASS}__list-item--active` : ''}`} 
     onClick={()=> { props.onSelect(id) }}
    >
      <p>
        {full_name} 
        <DeleteOutlined onClick={()=> { props.onDelete(props.data.id) }} className="delete-icon"/>
        <EditOutlined onClick={()=> { props.onEdit(props.data) }} className="edit-icon"/>
      </p>

      <p>
        { flat_no + ', ' + building_name + ', ' + area + ', ' + landmark + ', ' + city + ', ' + state +  ', ' + pincode }
      </p>
    </div>
  );
}
