import Login from "../../Login/Login";
import { connect } from 'react-redux';

function LoginModal(props) {
  const BLOCK_CLASS = "modal-wrapper--login";

  return (
    <div className={`${BLOCK_CLASS}`}>
      <Login />
    </div>
  );
}

function mapStateToProps(state, ownProps) {
  const { isUserLoggedIn } = state;
  return { isUserLoggedIn: isUserLoggedIn }
}

export default connect(mapStateToProps)(LoginModal);
