import React, { useEffect } from 'react';
const BLOCK_CLASS = "return";


const WashCare = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <div className="return-outer">
      <div className={`${BLOCK_CLASS}`}>
        <div className={`${BLOCK_CLASS}__title`}>
          <p>Wash Care</p>
        </div>
        <div className={`${BLOCK_CLASS}__inner`}>
          <div className={`${BLOCK_CLASS}__content`}>
            <p><b>FABRIC</b></p>
            <p>
              Our clothing is crafted from 100% pure cotton, a magical fabric
              that becomes softer and more absorbent with each wash. The true
              essence of Nilam clothing lies in the hand processes involved at
              every stage of creation. Customers consistently rave about the
              incredible softness of our fabrics, a testament to our commitment
              to quality and craftsmanship. Once you begin wearing quality
              fabrics, there's no compromising on your choice, and our aim is
              aligned with providing the best.
            </p>
            <p><b>SHRINKAGE</b></p>
            <p>
              During the dyeing and printing processes, the fabric is
              meticulously washed multiple times. This ensures thorough dye
              penetration and eliminates any residual surface dye. Consequently,
              the fabric is stabilized, preventing any further shrinkage after
              stitching.
            </p>
            <p><b>LAUNDERING</b></p>
            <p>
              All natural-dyed fabrics may experience some color bleeding during
              the first 2-3 washes, as excess dye is washed away. Due to the
              unique properties of natural dyes, these fabrics require special
              care. Think of it like self-love; the more care you give, the more
              you cherish them.
            </p>
            <p><b>WASH INSTRUCTIONS</b></p>
            <ul>
              <li>Hand wash delicately or dry clean only.</li>
              <li>Wash separately in cold water.</li>
              <li>Do not soak in water.</li>
              <li>
                To maintain color brilliance, add a handful of salt to the first
                rinse.
              </li>
              <li>
                Turn clothes inside out, especially dark colors, to reduce
                discoloration.
              </li>
              <li>Avoid drying in direct sunlight</li>
              <li>Do not use fabric softeners, bleach, or starch.</li>
            </ul>
            <p><b>IRONING</b></p>
            <p>
              Press with a warm to hot iron while the fabric is still damp. If
              it has dried, lightly mist it with a spray bottle. Always iron at
              a medium temperature, with the garment turned inside out.
            </p>
            <p>
              If you have any further concerns or questions, please connect with
              us on customercare@nilamind.com
            </p>
            <p>We will be happy to help you.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WashCare;