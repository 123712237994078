import { createAction } from '@reduxjs/toolkit';

export const showDrawer = createAction('SHOW_DRAWER');
export const hideDrawer = createAction('HIDE_DRAWER');

export const toggleMobileDrawerMenu = createAction('TOGGLE_MOBILE_MENU');

export const sendOTP = createAction('SEND_OTP');
export const sendOTPSuccess = createAction('SEND_OTP_SUCCESS');
export const sendOTPFailure = createAction('SEND_OTP_FAILURE');

export const logout = createAction('LOGOUT');

export const verifyOTP = createAction('VERIFY_OTP');
export const verifyOTPSuccess = createAction('VERIFY_OTP_SUCCESS');
export const verifyOTPFailure = createAction('VERIFY_OTP_FAILURE');

export const getCatalogue = createAction('GET_CATALOGUE');
export const getCatalogueSuccess = createAction('GET_CATALOGUE_SUCCESS');
export const getCatalogueFailure = createAction('GET_CATALOGUE_FAILURE');

export const getCategories = createAction('GET_CATEGORIES');
export const getCategoriesSuccess = createAction('GET_CATEGORIES_SUCCESS');
export const getCategoriesFailure = createAction('GET_CATEGORIES_FAILURE');

export const getSubCategories = createAction('GET_SUBCATEGORIES');
export const getSubCategoriesSuccess = createAction('GET_SUBCATEGORIES_SUCCESS');
export const getSubgetCategoriesFailure = createAction('GET_SUBCATEGORIES_FAILURE');

export const getProducts = createAction('GET_PRODUCTS');
export const getProductsSuccess = createAction('GET_PRODUCTS_SUCCESS');
export const getProductsFailure = createAction('GET_PRODUCTS_FAILURE');

export const togglePlaceOrderModal = createAction('TOGGLE_PLACE_ORDER_MODAL');

export const getCartDetails = createAction('GET_CART_DETAILS');
export const getCartDetailsSuccess = createAction('GET_CART_DETAILS_SUCCESS');
export const getCartDetailsFailure = createAction('GET_CART_DETAILS_FAILURE');
export const addToCart = createAction('ADD_TO_CART');
export const addToLocalCart = createAction('ADD_TO_LOCAL_CART');
export const createCart = createAction('CREATE_CART');
export const updateQuantityOfCartItem = createAction('UPDATE_QUANTITY_OF_CART_ITEM');
export const removeCartItem = createAction('REMOVE_CART_ITEM');
export const removeCartItemSuccess = createAction('REMOVE_ITEM_SUCCESS');
export const removeCartItemFailure = createAction('REMOVE_ITEM_FAILURE');

export const checkout = createAction('CHECKOUT');
export const checkoutSuccess = createAction('CHECKOUT_SUCCESS');
export const checkoutFailure = createAction('CHECKOUT_FAILURE');
export const clearOrderState = createAction('CLEAR_ORDER_STATE');

export const getPaymentStatus = createAction('GET_PAYMENT_STATUS');
export const paymentSuccess = createAction('PAYMENT_SUCCESS');
export const paymentFailure = createAction('PAYMENT_FAILURE');

export const getProductDetails = createAction('GET_PRODUCT_DETAILS');
export const getProductDetailsInProgress = createAction('GET_PRODUCT_DETAILS_INPROGRESS');
export const getProductDetailsSuccess = createAction('GET_PRODUCT_DETAILS_SUCCESS');
export const getProductDetailsFailure = createAction('GET_PRODUCT_DETAILS_FAILURE');


export const getAddresses = createAction('GET_ADDRESSES');
export const getAddressesSuccess = createAction('GET_ADDRESSES_SUCCESS');
export const getAddressesFailure = createAction('GET_ADDRESSES_FAILURE');

export const createAddress = createAction('CREATE_ADDRESS');
export const createAddressSuccess = createAction('CREATE_ADDRESS_SUCCESS');
export const createAddressFailure = createAction('CREATE_ADDRESS_FAILURE');

export const updateAddress = createAction('UPDATE_ADDRESS');
export const updateAddressSuccess = createAction('UPDATE_ADDRESS_SUCCESS');
export const updateAddressFailure = createAction('UPDATE_ADDRESS_FAILURE');

export const deleteAddress = createAction('DELETE_ADDRESS');
export const deleteAddressSuccess = createAction('DELETE_ADDRESS_SUCCESS');
export const deleteAddressFailure = createAction('DELETE_ADDRESS_FAILURE');

export const setAddressFormVisibility = createAction('SET_ADDRESS_FORM_VISIBILITY');

export const getOrders = createAction('GET_ORDERS');
export const getOrdersSuccess = createAction('GET_ORDERS_SUCCESS');
export const getOrdersFailure = createAction('GET_ORDERS_FAILURE');

export const getUserDetails = createAction('GET_USER_DETAILS');
export const getUserDetailsSuccess = createAction('GET_USER_DETAILS_SUCCESS');
export const getUserDetailsFailure = createAction('GET_USER_DETAILS_FAILURE');

export const updateUserDetails = createAction('UPDATE_USER_DETAILS');
export const updateUserDetailsSuccess = createAction('UPDATE_USER_DETAILS_SUCCESS');
export const updateUserDetailsFailure = createAction('UPDATE_USER_DETAILS_FAILURE');

export const getWishlist = createAction('GET_WISHLIST');
export const getWishlistSuccess = createAction('GET_WISHLIST_SUCCESS');
export const getWishlistFailure = createAction('GET_WISHLIST_FAILURE');

export const addToWishlist = createAction('ADD_TO_WISHLIST');
export const addToWishlistSuccess = createAction('ADD_TO_WISHLIST_SUCCESS');
export const addToWishlistFailure = createAction('ADD_TO_WISHLIST_FAILURE');

export const removeFromWishlist = createAction('REMOVE_FROM_WISHLIST');
export const removeFromWishlistSuccess = createAction('REMOVE_FROM_WISHLIST_SUCCESS');
export const removeFromWishlistFailure = createAction('REMOVE_FROM_WISHLIST_FAILURE');

export const getRecommendedProducts = createAction('GET_RECOMMENDED_PRODUCTS');
export const getRecommendedProductsSuccess = createAction('GET_RECOMMENDED_PRODUCTS_SUCCESS');
export const getRecommendedProductsFailure = createAction('GET_RECOMMENDED_PRODUCTS_FAILURE');

export const showToastMessage = createAction('SHOW_TOAST_MESSAGE');
export const clearToastMessage = createAction('CLEAR_TOAST_MESSAGE');

export const showAppLoader = createAction('SHOW_APP_LOADER');
export const hideAppLoader = createAction('HIDE_APP_LOADER');

export const cancelOrder = createAction('CANCEL_ORDER');
export const cancelOrderSuccess = createAction('CANCEL_ORDER_SUCCESS');
export const cancelOrderFailure = createAction('CANCEL_ORDER_FAILURE');

export const subscribeEmail = createAction('SUBSCRIBE_EMAIL');
export const subscribeEmailSuccess = createAction('SUBSCRIBE_EMAIL_SUCCESS');

export const checkPincode = createAction('CHECK_PINCODE');
export const checkPincodeSuccess = createAction('CHECK_PINCODE_SUCCESS');
export const clearPincodeStatus = createAction('CLEAR_PINCODE_STATUS');