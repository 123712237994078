import { connect } from "react-redux";
import { Link } from "react-router-dom";
import WishlistCard from "../../components/Card/WishlistCard";
import SideNavigation from '../../components/SideNavigation/SideNavigation';
import { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { toggleMobileDrawerMenu } from '../../store/actions';
import ImageWrapper from "../../components/Image/ImageWrapper";

const WISHLIST_CLASS = "wishlist";
const BLOCK_CLASS = "account";

function Wishlist(props) {
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.userDetails);
  const isMobileMenuOpen = useSelector((state) => state.isMobileMenuOpen);

  useEffect(()=> {
    window.scrollTo(0,0);
    if (isMobileMenuOpen) {
      dispatch(toggleMobileDrawerMenu());
    }
  }, []);

  return (
    <div className="account-main">
      <div className={`${BLOCK_CLASS}`}>
        <div className={`${BLOCK_CLASS}__title`}>
          <p>
            Welcome!{" "}
            {userDetails?.first_name ? userDetails?.first_name : "User"}
          </p>
        </div>
        <div className={`${WISHLIST_CLASS}__page`}>
          <SideNavigation />
          <div className={`${WISHLIST_CLASS}`}>
            {/* <h4 className={`${WISHLIST_CLASS}__title`}>Wishlist</h4> */}
            <div className={`${WISHLIST_CLASS}__items ${props?.wishlist?.items?.length == 0 ? `${WISHLIST_CLASS}__items--empty` : ''}`}>
              {props?.wishlist?.items?.map((product, i) => {
                return (
                  <WishlistCard
                    data={product}
                    key={"listing_" + product.variant_sku}
                  />
                );
              })}
              
              {props?.wishlist?.items?.length == 0 ? (
                <ImageWrapper
                  name="no-wishlist"
                  alt="empty wishlist"
                  classes={`${WISHLIST_CLASS}__no-items`}
                ></ImageWrapper>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
    // <div className={`${WISHLIST_CLASS}`}>
    //   <h3>Wishlist</h3>

    //   <div className={`container`}>
    //     {props?.wishlist?.items?.map((product, i) => {
    //       return (
    //         <Link
    //           to={`/product/${product.variant_sku}`}
    //           state={product}
    //           key={"listing_" + product.variant_sku}
    //         >
    //           <WishlistCard data={product} />
    //         </Link>
    //       );
    //     })}
    //   </div>
    // </div>
  );
}

function mapStateToProps(state, ownProps) {
  const { wishlist } = state;
  return { wishlist };
}

export default connect(mapStateToProps)(Wishlist);
