import ImageWrapper from "../../components/Image/ImageWrapper";
import { useEffect} from "react";

export default function About(props) {
  const BLOCK_CLASS = "about";

  useEffect(()=> {
    window.scrollTo(0,0);
  }, [])

  return (
    <div className={`${BLOCK_CLASS}`}>
      <div className={`${BLOCK_CLASS}__title`}>
        <h3>About</h3>
        <h4>Nilam</h4>
      </div>

      <div className={`${BLOCK_CLASS}__first-row`}>
        <ImageWrapper
          name="nilam-in-person"
          alt="Nilam back in the days"
        ></ImageWrapper>

        <div className={`${BLOCK_CLASS}__primary-content`}>
          <p>
            In the heart of the enchanting city of Dehradun, there lived a soul
            as captivating as the landscape itself, Nilam.
          </p>
        </div>
      </div>

      <div className={`${BLOCK_CLASS}__second-row`}>
        <p>
          She was a mosaic of passions, a lover of beauty in all its forms. From
          the lines and colours of design to the gentle sway of trees in the
          mountain breeze, Nilam found inspiration in every corner of her world.
          But more than her appreciation for aesthetics, Nilam was a facilitator
          for those around her. With a heart as vast and welcoming as the
          mountains, she was always there for others – a comforting presence, a
          compassionate listener, a guiding light through life's twists and
          turns.Yet, Nilam was no stranger to the darkness that sometimes crept
          into her own life. There were days when the weight of the world felt
          heavy on her shoulders, when doubts and fears threatened to overshadow
          her bright spirit. In those moments, Nilam turned to one of her
          greatest sources of solace – the transformative power of
          self-expression.
        </p>

        <p>
          Nilam believed in equality, be it an opportunity, to strive, to dream,
          to work or to love. Passionate about geometry, she pushed herself and
          those around her to realise their potential even if that meant going
          beyond what one thought they were capable of. For her, perfection lay
          in achieving the best version of what one did. Her simple but classy
          dressing sense became her identity, her branding if you will. The
          colour palette and fabrics she used for her dressing, whether it was
          for an occasion, or just for a regular day at work, instilled the
          comfort and confidence she needed to take on the world, fight her
          battles, and give her everything to what she did.
        </p>

        <p>
          Adorned in her own unique blend of style and grace, Nilam ventured out
          into the world. She was a living testament to the resilience of the
          human spirit, a reminder that even in the darkest of times, there is
          always beauty to be found – if only we have the courage to look for
          it.
        </p>
      </div>
      <ImageWrapper
        name="Layer_1"
        alt="background image"
        classes={`${BLOCK_CLASS}__bottom-image`}
      ></ImageWrapper>
    </div>
  );
}
