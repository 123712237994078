import React, { useEffect } from "react";
import { Collapse } from "antd";
const general = [
  {
    key: "1",
    label: "WILL YOU BE RESTOCKING SOLD OUT ITEMS?",
    children: (
      <p>
        We work on a slow-fashion model using only sustainable fibres, which
        means that each of our styles are in development for 1-2 months. So,
        signing up to our mailing list to be the first to find out when new
        collections arrive, and regularly checking back in with us is the best
        solution, so that you don’t miss out.
      </p>
    ),
  },
  {
    key: "2",
    label: "IS CASH ON DELIVERY (COD) AVAILABLE?",
    children: (
      <p>
        No we don't provide COD services as we understand that it becomes very
        difficult for the customer to pay money in hand and to the delivery
        partner. Online payment is the safest option and we like to give you all
        the transaction details so that you are informed well.
      </p>
    ),
  },
  {
    key: "3",
    label: "HOW TO PLACE AN RETURN / EXCHANGE REQUEST?",
    children: (
      <>
        <p>We do not offer Refunds and we accept Returns against Exchange only for size issues</p>
        <ul className="list">
          <li>
            - We do not offer Refunds and we accept Returns (if there's size issue, you may inform us directly on 8979733011 and we can arrange for return and send as per the revised size)
          </li>
          <li>
            - If its a defected peice then you may inform us directly on 8979733011 and also send pictures/videos @ customercare@nilamind.com, we will arrange the pick up and post the verification, we will initiate the refund in 4-5 working days.
          </li>
          <li>
            Please note that the Exchange request needs to be raised within 3
            days from the delivery date. Reverse pickup will be done in 2-3
            working days by our courier partner. Once we receive the product, we
            would get in touch with you to confirm your request.
          </li>
          <li>
            In addition, all returned products must be unused or unwashed or
            undamaged and must be returned with the original packing and tags
            when we receive them. Items without tags will not be accepted. And
            if such is the case, you won't be eligible for the refund.
            Please share the package unboxing video/pictures for wrong product / Missing item received on customercare@nilamind.com 
          </li>
          <li>
            Note - We are very careful with the fraudlent activity that happens
            in Ecommerce industry and we want to protect both customer and
            people working in our payment/delivery/production team to have a
            hasselfree expperience with Nilam India.
          </li>
        </ul>
      </>
    ),
  },
  {
    key: "4",
    label: "ARE THERE ANY ADDITIONAL CHARGES FOR RETURNS/EXCHANGE?",
    children: (
      <p>
        We levy a charge of INR 200 for any return orders as reverse shipment
        fee at the time of exhange
      </p>
    ),
  },
  {
    key: "5",
    label: "HOW LONG WILL MY ORDER TAKE TO ARRIVE?",
    children: (
      <p>
        Ours is a handcrafted product and it takes time to create one single
        product. At times we might face stock avaibility issue hence the order
        usually takes 3-6 working days to reach all the metros and tier 2
        cities, however for some pin codes it might take a little more time. We
        will always try our best to deliver your product at the earliest and in
        case of delay please get in touch with us on customercare@nilamind.com
      </p>
    ),
  },
  {
    key: "6",
    label: "HOW WOULD I KNOW IF MY ORDER IS PLACED?",
    children: (
      <p>
        You will get a confirmation of the placed order on your registered email
        ID and phone number. Besides, we will further notify you once it is
        dispatched from our warehouse.
      </p>
    ),
  },
  {
    key: "7",
    label: "WHAT HAPPENS IF MY SHIPMENT DOES NOT GET DELIVERED ON TIME?",
    children: (
      <p>
        In case the order does not get delivered within 7-10 working days, you
        can write to us at: customercare@nilamind.com or send a message on our
        Instagram account We will do our best to get it delivered at the
        earliest.
      </p>
    ),
  },
  {
    key: "8",
    label: "ARE THERE ANY SHIPPING CHARGES ON DELIVERY?",
    children: (
      <p>
        We don't charge delivery fees from our customers on order above Rs,
        1499. Product below Rs.1499, basis the location delivery charges may
        apply.
      </p>
    ),
  },
  {
    key: "9",
    label: "DO WE SHIP OUTSIDE INDIA?",
    children: (
      <p>
        We are still growing and at the moment, we do not deliver items outside
        India. However, you can make a purchase from anywhere in the world as
        long as the shipping address is within India.
      </p>
    ),
  },
];

const cancel = [
  {
    key: "1",
    label: "HOW CAN I CANCEL AN ORDER?",
    children: (
      <p>
        You can cancel you order within 3 hours of placing the order via
        website. You can opt to cancel order by clicking the cancel order button
        on the purchased product section.
      </p>
    ),
  },
  {
    key: "2",
    label: "HOW DO I RETURN / EXCHANGE A PRODUCT?",
    children: (
      <>
        <p>
          You need to pack the product back in its original packing (with tags
          unremoved) and handover the packet to our courier person. As soon as
          we have it back in our warehouse, we will verify and issue a refund
          only if its a defected peice. If it's a size issue then accordingly we
          will send the revised size. Incase the product is not delivered in the
          condition then we would not move ahead with the refund and the same
          will be communicated to you. So it's your responsibility to care of
          the product if you want to refund it.
        </p>
        <p>
          Please note that handling fee of INR 200 is charged for returns. In
          addition, order placed during any sale/discounts are not eligible for
          returns / exchanges
        </p>
      </>
    ),
  },
  {
    key: "3",
    label:
      "WHAT HAPPENS TO ORDERS WHERE PRODUCTS ARE RECEIVED IN DAMAGED CONDITION?",
    children: (
      <>
        <p>
          We strive to deliver the best quality standards, however, in case you
          receive a damaged / defective product, we should be notified within 24
          hours of delivery. Also, we request you to email us a photograph of
          the damaged / defective product on customercare@nilamind.com
          <br />
          Incase you fail to intimate about the same in 24hrs, return might not
          be processed.
        </p>
      </>
    ),
  },
  {
    key: "4",
    label:
      "WHAT SHOULD I DO IF I RECEIVE A DIFFERENT ITEM FROM THE ONE THAT I HAVE ORDERED?",
    children: (
      <p>
        We try our best to deliver the right products within time, however, in a
        few rare cases if you receive a different / wrong product, please visit
        the returns section on the website or click here to raise a return
        request if you receive any wrong order with the comments and picture.
      </p>
    ),
  },
  {
    key: "5",
    label: "WITHIN HOW MANY DAYS OF PURCHASE CAN I RETURN MY ORDER?",
    children: (
      <p>
        Return request needs to be raised within 3 days of the delivery
        date/time. We believe that honest customers can determine size issue
        within a short period. Therefore, we feel that a return period beyond 3
        days is unnecessary.
      </p>
    ),
  },
  {
    key: "6",
    label: "I HAVE REQUESTED A REPLACEMENT, WHEN WILL I GET IT?",
    children: (
      <p>
        For return / exchange, the reverse pick up will be done in 2-3 days once
        the request is placed. It takes 7-10 days for the product to arrive at
        our warehouse, post which it undergoes a quality check. Once it passes
        the QC, the refund for return or the request for exchange will be
        initiated within 72 hours. Once the product is dispatched, it will take
        7-10 working days to reach you. The timeline differs as per the
        locations, our focus will always be to get things faster and better for
        our customers. For further assistance please get us in touch @
        customercare@nilamind.com
      </p>
    ),
  },
  {
    key: "7",
    label: "WHAT HAPPENS IF MY SHIPMENT DOES NOT GET DELIVERED ON TIME?",
    children: (
      <p>
        In case the order does not get delivered within 7-10 working days, you
        can write to us at: customercare@nilamind.com or send a message on our
        Instagram account We will do our best to get it delivered at the
        earliest.
      </p>
    ),
  },
  {
    key: "8",
    label: "CAN I RETURN ALL ITEMS?",
    children: (
      <p>
        Accessories cannot be returned keeping hygiene into consideration. In
        addition, orders including any free products received during promotional
        events/offers are not eligible for returns / exchanges.
      </p>
    ),
  },
  {
    key: "9",
    label: "WHEN WILL I GET MY REFUND?",
    children: (
      <>
        <p>
          It takes 7-10 days for the product to arrive at our warehouse after
          the reverse pickup is done, after which it undergoes a quality check.
          Once it passes the QC, the exchange request will be initiated within
          72 hours. Our focus will always be to get things faster and better for
          our customers. Shipping fee, Express/Priority Shipping fee,
          Customization charges, Alteration charges & Gift Packing charges are
          non-refundable
        </p>
        <p>
          You can exhange any product basis the price value paid and can pay the
          remaining amount as per the adjusted amount paid earlier.
        </p>
      </>
    ),
  },
  {
    key: "10",
    label: "HOW DO I GET MY REFUND FOR DISCOUNTED ITEMS?",
    children: (
      <p>
        You can exhange any product basis the price value paid and can pay the
        remaining amount as per the adjusted amount paid earlier.
      </p>
    ),
  },
  {
    key: "11",
    label: "HOW DO I GET MY REFUND FOR PREPAID ORDERS?",
    children: (
      <p>
        You can exhange any product basis the price value paid and can pay the
        remaining amount as per the adjusted amount paid earlier.
      </p>
    ),
  },
  {
    key: "12",
    label: "HOW DO I GET MY REFUND FOR CASH ON DELIVERY ORDERS?",
    children: <p>We do not have COD option</p>,
  },
  {
    key: "13",
    label: "HOW DO I CHECK THE STATUS FOR MY RETURNED ORDER?",
    children: (
      <p>
        For further information on your returned order, you may log into your
        account or reach us at customercare@nilamind.com{" "}
      </p>
    ),
  },
];

const sizing = [
  {
    key: "1",
    label: "HOW DO I DETERMINE THE CORRECT SIZE FOR ME?",
    children: (
      <p>
        While placing your order you will find an option for the SIZE CHART on
        the product page which includes detailed information on sizes and fit.
        You may click on the same and find your perfect size.
      </p>
    ),
  },
];

const payments = [
  {
    key: "1",
    label:
      "MY PAYMENT WAS DEDUCTED FROM THE BANK ACCOUNT BUT THE ORDER WASN’T PLACED. WHAT SHOULD I DO?",
    children: (
      <p>
        In most cases, the payment gets automatically credited back to you
        account within 2-3 business days. In case it does not, mail us the
        details of your transaction at customercare@nilamind.com. We will check
        and let you know the status however it is possible that the bank would
        take sometime to reverse the transaction so may also check with your
        bank.
      </p>
    ),
  },
  {
    key: "2",
    label: "WHAT CURRENCY IS USED ONLINE? ",
    children: <p>All payments made on this site are in Indian Rupees.</p>,
  },
];

const Faqs = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <div className="faqs-main">
      <div className="faqs-inner">
        <div className="faqs-title">
          <p>Faqs</p>
        </div>
        <div className="general">
          <div className="general-title">
            <p>GENERAL QUERIES</p>
          </div>
          <div className="faq-accordion">
            <Collapse accordion items={general} />
          </div>
        </div>
        <div className="general">
          <div className="general-title">
            <p>CANCELLATION, RETURN / EXCHANGE & REFUND</p>
          </div>
          <div className="faq-accordion">
            <Collapse accordion items={cancel} />
          </div>
        </div>
        <div className="general">
          <div className="general-title">
            <p>SIZING HELP</p>
          </div>
          <div className="faq-accordion">
            <Collapse accordion items={sizing} />
          </div>
        </div>
        <div className="general">
          <div className="general-title">
            <p>PAYMENTS</p>
          </div>
          <div className="faq-accordion">
            <Collapse accordion items={payments} />
          </div>
        </div>
        <div className="general">
          <div className="general-title">
            <p>Disclaimers</p>
          </div>
          <div className="disclaimer">
            <p>
              Nilam does not send any direct email for collaboration/ paid
              partnership to any person/content creator/customer, nor
              call/message to ask for bank details, OTP or any other personal
              sensitive information.
            </p>
            <p>
              Nilam shall in no way be held responsible for any loss, liability
              or obligation, nor does it assume any responsibility or liability
              for action of any third party.
            </p>
            <p>
              Need help more help? <br />
              Call us @ 8979733011
              <br />
              Mon - Sat: 9 am to 6 pm (IST)
              <br />
              Write to us at customercare@nilamind.com
            </p>
            <p>
              Shipping Address:
              <br />
              Nilam India
              <br />
              E-203 Doon Traflagar, Near IT Park
              <br />
              Dehradun - 248001
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Faqs;
