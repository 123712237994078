import ImageWrapper from "../../components/Image/ImageWrapper";

const ICON_CLASS = "icon";

const Features = () => {
  return (
    <section className="home-icon">
      <div className="container">
        <div className={`${ICON_CLASS}`}>
          <ul className={`${ICON_CLASS}__icon-list`}>
            <li className={`${ICON_CLASS}__icon-item`}>
              <figure className={`${ICON_CLASS}__icon-figure`}>
                <ImageWrapper
                  name="gentle-skin"
                  alt="Gentle on skin"
                  classes={`${ICON_CLASS}__icon`}
                />
                <figcaption>
                  Gentle <br /> on skin
                </figcaption>
              </figure>
            </li>
            <li className={`${ICON_CLASS}__icon-item`}>
              <figure className={`${ICON_CLASS}__icon-figure`}>
                <ImageWrapper
                  name="best-fabrics"
                  alt="Best Fabrics"
                  classes={`${ICON_CLASS}__icon`}
                />
                <figcaption>Finest Fabrics</figcaption>
              </figure>
            </li>
            <li className={`${ICON_CLASS}__icon-item`}>
              <figure className={`${ICON_CLASS}__icon-figure`}>
                <ImageWrapper
                  name="handled-with-love"
                  alt="Handled with love"
                  classes={`${ICON_CLASS}__icon`}
                />
                <figcaption>
                  Quality Assured
                </figcaption>
              </figure>
            </li>
            <li className={`${ICON_CLASS}__icon-item`}>
              <figure className={`${ICON_CLASS}__icon-figure`}>
                <ImageWrapper
                  name="handcrafted"
                  alt="Handcrafted"
                  classes={`${ICON_CLASS}__icon`}
                />
                <figcaption>Handmade by 
                  <br></br>Artisans</figcaption>
              </figure>
            </li>
            <li className={`${ICON_CLASS}__icon-item`}>
              <figure className={`${ICON_CLASS}__icon-figure`}>
                <ImageWrapper
                  name="people-of-india"
                  alt="Made by people of India"
                  classes={`${ICON_CLASS}__icon`}
                />
                <figcaption>
                  Made by <br />
                  people of India
                </figcaption>
              </figure>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Features;
