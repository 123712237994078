import { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Home from "./pages/Home/Home";
import Subcategory from "./pages/Sub-Category/Sub-category";
import { Routes, Route } from "react-router-dom";
import "./scss/globals/styles.scss";
import ProductList from "./pages/ProductList/ProductList";
import Product from "./pages/Product/Product";
import {
  getCategories,
  getCartDetails,
  togglePlaceOrderModal,
  createCart,
  getCatalogue,
  getAddresses,
  getUserDetails,
  getWishlist
} from "./store/actions";
import Profile from "./pages/Account/Profile";
import Orders from "./pages/Account/Orders";
import Terms from "./pages/Terms/Terms";
import Faqs from "./pages/Faqs/Faqs";
import Privacy from "./pages/Privacy/Privacy";
import Returns from "./pages/Returns/Returns";
import Payment from "./pages/Payment/Payment";
import { useNavigate } from "react-router-dom";
import PaymentVerification from "./pages/PaymentVerification/PaymentVerification";
import Clean from "./components/Clean/Clean";
import ManageAddresses from "./pages/Account/ManageAddresses";
import Wishlist from "./pages/Account/Wishlist";
import About from "./pages/About/About";
import Contact from "./pages/Contact/Contact";
import WashCare from "./pages/WashCare/WashCare";

const Root = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isUserLoggedIn = useSelector((state) => state.isUserLoggedIn);
  const cartDetails = useSelector((state) => state.cartDetails);
  const paymentURL = useSelector((state) => state.paymentURL);

  useEffect(() => {
    dispatch(getCategories());
    dispatch(getCatalogue());
    if (isUserLoggedIn) {
      dispatch(getCartDetails());
      dispatch(getUserDetails());
      dispatch(getWishlist());
    }
	}, []);

  useEffect(() => {
    if (isUserLoggedIn) {
      dispatch(getCartDetails());
      dispatch(getUserDetails());
      dispatch(getWishlist());
    }
  }, [isUserLoggedIn])
  
  useEffect(() => {
    if (paymentURL !== undefined && paymentURL !== null) {
      dispatch(togglePlaceOrderModal());
      navigate("/payment"); 
    }
	}, [paymentURL]);
  
  useEffect(() => {
    if (shouldCreateCart()) {
      dispatch(createCart(cartDetails.items));
    }

    if (isUserLoggedIn) {
      dispatch(getAddresses());
    }
	}, [isUserLoggedIn]);

  const shouldCreateCart = () => {
    let isPaymentVerificationScreen = window.location.href.includes('payment-verification'); 
    return (
      isUserLoggedIn &&
      cartDetails?.id == undefined &&
      cartDetails?.items.length > 0 &&
      isPaymentVerificationScreen == false
    );
  } 

  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="/collection/:category_name/:subcategory_name?"
          element={<Subcategory />}
        />
        <Route
          path="products/:category_name?/:subcategory_name"
          element={<ProductList />}
        />
        <Route path="/product/:sku" element={<Product />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/orders" element={<Orders />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/faqs" element={<Faqs />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/refund" element={<Returns />} />
        <Route path="/payment" element={<Payment />} />
        <Route path="/payment-verification" element={<PaymentVerification />} />
        <Route path="/clean" element={<Clean />} />
        <Route path="/address" element={<ManageAddresses />} />
        <Route path="/wishlist" element={<Wishlist/>} />
        <Route path="/about-us" element={<About/>} />
        <Route path="/contact" element={<Contact/>} />
        <Route path="/washcare" element={<WashCare/>} />
      </Routes>
    </>
  );
}

export default Root;
