import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import ImageWrapper from "../../components/Image/ImageWrapper";
import '../Carousel/Carousel.scss'

const Carousel = () => {
    const options = {
        items: 1,
        nav: false,
        rewind: true,
        autoplay: true,
        slideBy: 1,
        dots: true,
        dotsEach: true,
        dotData: true,
        responsiveClass:true,
        responsive:{
            0:{
                items:1,
            },
            600:{
                items:1,
            },
            1000:{
                items:1,
            }
        }
    }
    return (
        <div className='customer-slider'>
            <OwlCarousel items={1} className="owl-theme" loop nav margin={8} {...options}>  
                <div className='items'> 
                    <figure className='customer-img'>
                        <ImageWrapper name="customer" alt="our customer"/>
                    </figure>
                    <div className='customer-content'>
                        <p>The cloth is of good quality and the curtains were stitched neatly. 
                            It’s been 10 years and I still use them in my living room.</p>
                        <p>Suchitra B</p>
                    </div>
               </div>
               <div className='items'> 
                    <figure className='customer-img'>
                        <ImageWrapper name="customer-2" alt="our customer"/>
                    </figure>
                    <div className='customer-content'>
                        <p>One of the best hand block print collection, all products are crafted with utmost precision and perfection.</p>
                        <p>Annlin Martins</p>
                    </div>
               </div> 
               <div className='items'> 
                    <figure className='customer-img'>
                        <ImageWrapper name="customer-3" alt="our customer"/>
                    </figure>
                    <div className='customer-content'>
                        <p>The custom-made, impeccably printed bedcovers, table runners, table covers, napkins and cushion 
                            covers are still as bright as new, despite being used regularly for almost a decade.</p>
                        <p>Amena Fatehally</p>
                    </div>
               </div>
            </OwlCarousel>  
        </div>  
    );
};

export default Carousel;