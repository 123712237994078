import { useDispatch, useSelector, connect } from "react-redux";
import { Button, Drawer, Select } from "antd";
import {
  hideDrawer,
  togglePlaceOrderModal,
  updateQuantityOfCartItem,
  removeCartItem,
  addToCart
} from "../../store/actions";
import { DRAWER_TYPES } from "../../utils/enumData";
import ImageWrapper from "../Image/ImageWrapper";
import { Link } from "react-router-dom";

function Cart(props) {
  const BLOCK_CLASS = "cart";
  const dispatch = useDispatch();
  const isCartVisible = useSelector((state) => state.isCartVisible);

  const onClose = () => {
    dispatch(hideDrawer({ type: DRAWER_TYPES.cart }));

  };

  const placeOrder = () => {
    dispatch(hideDrawer({ type: DRAWER_TYPES.cart }));
    dispatch(togglePlaceOrderModal({ type: 'placeorder' }));
  };

  const removeItemfromCart = (data) => {
    dispatch(removeCartItem({itemId: data.cart_item_id, isUserLoggedIn: props.isUserLoggedIn }));
  };

  const getTotalPrice = (value) => {
    let totalAmount = 0;
    props?.cartDetails?.items?.forEach(product => {
      totalAmount = totalAmount + (product.total_price * product.quantity);
    });

    return totalAmount;
  };

  // Current quantity should be compared with the inventory quantity of the current product.
  const addItemToCart = (e, quantity, data) => {
    e.preventDefault();
    if (quantity > 0 && quantity <= props?.variantDetails?.quantity) {
      let updatedItem = { ...data, quantity: quantity, isUserLoggedIn: props.isUserLoggedIn };
      dispatch(addToCart({ data: updatedItem }));
    }
  };

  return (
    <Drawer
      title="CART"
      onClose={onClose}
      open={isCartVisible}
      className={`${BLOCK_CLASS} ${isCartVisible ? "" : "no-pad"}`}
    >
      <div className={`${BLOCK_CLASS}__items-wrapper`}>
        {
          props?.cartDetails?.items?.length > 0 ?
          (props.cartDetails.items.map((data) => {
            return (
              <div className={`${BLOCK_CLASS}__item`} key={data.cart_item_id}>
                <a href={`/product/${data.variant_id}`}>
                  <ImageWrapper
                    externalUrl={data.thumbnail}
                    name="women"
                    alt="women"
                    classes={`${BLOCK_CLASS}__item-image`}
                  />
                </a>
                <div className={`${BLOCK_CLASS}__item-details`}>
                  <a href={`/product/${data.variant_id}`}>
                    <h4
                      className={`${BLOCK_CLASS}__item-label ${BLOCK_CLASS}__item-label--name`}
                    >
                      {data.product_name}
                    </h4>
                  </a>
                  <a href={`/product/${data.variant_id}`}>
                    <div className={`${BLOCK_CLASS}__item-cta-wrapper`}>
                      {data?.variant_properties?.size ? (
                        <h4
                          className={`${BLOCK_CLASS}__item-label ${BLOCK_CLASS}__item-label--size`}
                        >
                          Size:{" "}
                          <span className={`${BLOCK_CLASS}__item-label--value`}>
                            {data?.variant_properties?.size}
                          </span>
                        </h4>
                      ) : (
                        ""
                      )}
                      <div className={`${BLOCK_CLASS}__quantity-wrapper`}>
                        <Button
                          onClick={(e) => {
                            addItemToCart(e, data.quantity - 1, data);
                          }}
                        >
                          -
                        </Button>{" "}
                        {data.quantity}{" "}
                        <Button
                          onClick={(e) => {
                            addItemToCart(e, data.quantity + 1, data);
                          }}
                        >
                          +
                        </Button>
                      </div>
                    </div>
                  </a>
                  <div className={`${BLOCK_CLASS}__price-block`}>
                    <h4
                      className={`${BLOCK_CLASS}__item-label ${BLOCK_CLASS}__item-label--price`}
                    >
                      Rs. {data.total_price}
                    </h4>
                    <ImageWrapper
                      name="delete"
                      alt="remove item"
                      classes={`${BLOCK_CLASS}__remove-icon`}
                      onclick={() => {
                        removeItemfromCart(data);
                      }}
                    ></ImageWrapper>
                  </div>
                </div>
              </div>
            );
          })) : (
            <>
              <ImageWrapper name="empty-cart" alt="empty cart" classes={`${BLOCK_CLASS}__empty-cart`}></ImageWrapper>
              <p className={`${BLOCK_CLASS}__empty-cart-text`}>An empty cart isn’t your vibe</p>
            </>
          )
        }
      </div>
      <div className={`${BLOCK_CLASS}__summary`}>
      <p className={`${BLOCK_CLASS}__amount-label-shipping`}>
          <span>SHIPPING CHARGES <small></small></span>
          {
            props?.cartDetails?.items?.length > 0 ? (
              <span><span className={`slashed-price`}>Rs. 209</span> <b>Free</b></span>
            ) : (<span>Rs.0</span>)
          }
        </p>
        <p className={`${BLOCK_CLASS}__amount-label`}>
          <span>SUB TOTAL <small>(incl taxes)</small></span>
          <span>Rs.{props.isUserLoggedIn && props.cartDetails?.total ? props.cartDetails.total : getTotalPrice()}</span>
        </p>

        <div className={`${BLOCK_CLASS}__btn-wrapper`}>
          <Button className={`${BLOCK_CLASS}__primary-cta`} onClick={placeOrder} disabled={props?.cartDetails?.items?.length > 0 ? false : true}>
            Place order
          </Button>
          <Button className={`${BLOCK_CLASS}__secondary-cta`} onClick={onClose}><Link to="/collection/Clothing">Continue shopping</Link></Button>
        </div>
      </div>
    </Drawer>
  );
}

function mapStateToProps(state, ownProps) {
  const { cartItems, cartDetails, isUserLoggedIn, product } = state;
  return {
    cartItems: cartItems,
    cartDetails,
    isUserLoggedIn,
    variantDetails: product ? product.variantDetails : null,
  };
}

export default connect(mapStateToProps)(Cart);
