import Carousel from '../../components/Carousel/Carousel';

const CUSTOMER_CLASS = "customer";

const Testimonials = () => {
  return (
    <section className="our-customer">
      <div className="container">
        <div className={`${CUSTOMER_CLASS}`}>
          <div className={`${CUSTOMER_CLASS}__title`}>
            <h3>Our</h3>
            <h4>Community</h4>
          </div>
          <Carousel />
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
