import React, { useEffect } from 'react';
const BLOCK_CLASS = "terms";


const Terms = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <div className='terms-outer'>
        <div className={`${BLOCK_CLASS}`}>
            <div className={`${BLOCK_CLASS}__title`}>
                <p>Terms of Use</p>
            </div>
            <div className={`${BLOCK_CLASS}__inner`}>
            <div className={`${BLOCK_CLASS}__content`}>
              <p>Welcome to Nilam.</p>
              <p>This website is operated by Nilam. Throughout the site, the terms “we”, “us” and “our” refer to Nilam. We offer this website, including all information, tools and services available from this site to you, the user, conditioned upon your acceptance of all terms, conditions, policies and notices stated here. By accessing Nilam online, you acknowledge that you have read, understood and accepted these terms and conditions. If you would like any further information or clarification of these terms and conditions please contact us. The following terms and conditions may be amended or modified at any time without any prior notice to an individual. It is our policy to comply with general laws for protecting user information and bank details shared for the purpose of availing our services. This regulates the processing of information relating to you and grants you various rights in respect of your personal data. Nilam is not responsible for the privacy policies or practices of other web sites to which you choose to link to <a href="nilamind.com" target='_blank'>nilamind.com</a> However, if at any time in the future Nilam plans to use personal information or location-based data in a way that materially differs from this Privacy Policy, such as sharing such information with more third parties, Nilam, will post such changes here and provide Users the opportunity to opt-out of such differing uses. Users, continuing to use our services, following the posting of any changes to this Privacy Policy means User’s acceptance to such changes. If you use this site, you are responsible for maintaining the confidentiality of your account and password and for restricting access to your computer, and you agree to accept responsibility for all activities that occur under your account or password.</p>
              <p>Whilst we try and ensure that the details, descriptions and prices of the products displayed on the website are accurate, errors may occur. We therefore reserve the right to correct any errors, inaccuracies or omissions and to change or update information at any time without prior notice. We reserve the right to refuse to fulfil any order that you may place based on such erroneous, inaccurate or out-of-date information regarding pricing, shipping, payment terms, or return policies.</p>
              <p>To the maximum extent permitted by applicable law, Nilam assumes no liability or responsibility for (i) any errors, mistakes, or inaccuracies of content displayed on the website; (ii) any personal injury or property damage, of any nature whatsoever, resulting from your access to or use of our website or any product; (iii) any unauthorized access to or use of our secure servers and/or any and all personal information stored therein; (iv) any interruption or cessation of transmission to or from the website; and/or (v) any bugs, viruses or the like that may be transmitted to or through our website by any third party.</p>
              <p>Colours: We have done our best to display as accurately as possible the colours of the products shown on this Web site. However, because the colours you see will depend on your monitor, we cannot guarantee that your monitor's display of any colour will be accurate.Reds and blues tend to run. Please always wash like colours together and dry inside out in the shade. Silks must only be dry cleaned.</p>
              <p>Nilam attempt to be as accurate as possible. However, we do not warrant that product descriptions or other content of this site is accurate, complete, reliable, current, or error-free. If a product offered by Nilam itself is not as described, your sole remedy is to return it in unused condition.</p>
              <p className='service'>Use of the Nilam Service is governed by, and subject to the Terms of Service. This Privacy Policy is incorporated into such terms.</p>
              <p>CONTACT:</p>
              <p>
              Call us @ 8979733011 <br/>
              For questions or concerns relating to privacy, feel free to contact us at <a href='mailto:customercare@nilamind.com'>customercare@nilamind.com</a> <br/>
              Address: 207 Rajpur Road, Dehradun - 248009, Uttarakhand
              </p>


              </div>
            </div>


        </div>

    </div>
  )
}

export default Terms;