import { configureStore, applyMiddleware } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import saga from './saga';
import { reducer } from './reducer';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['cartDetails', 'isUserLoggedIn']
}

const persistedReducer = persistReducer(persistConfig, reducer);

// create the saga middleware
const sagaMiddleware = createSagaMiddleware()

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(sagaMiddleware),
});

// then run the saga
sagaMiddleware.run(saga)

const persistor = persistStore(store);


export default { store, persistor };