
import axiosInstance from '../services/interceptor';
// const baseURL = 'https://www.api.staging.nilamind.com';
const baseURL = 'https://api.nilamind.com';

const sendOTP = async function (data) {
    return axiosInstance.post(`${baseURL}/otps/send_otp`, data).then((response) => {
      return (response);
    });
};

const verifyOTP = async function (data) {
    return axiosInstance
    .post(`${baseURL}/otps/verify`, data)
    .then((response) => {
      return response;
    });
};

const getCategories = async function (data) {
  return axiosInstance
  .get(`${baseURL}/categories?page=1&limit=10`)
  .then((response) => {
    return response;
  });
};

const getSubCategories = async function (data) {
  return axiosInstance
  .get(`${baseURL}/categories?page=1&limit=10&parent_category_name=${data.category_name}`)
  .then((response) => {
    return response;
  });
};

const getProducts = async function (data) {
  return axiosInstance
  .get(`${baseURL}/products?page=1&limit=10`, { params: data})
  .then((response) => {
    return response;
  });
};

const getVariant = async function (data) {
  return axiosInstance
  .get(`${baseURL}/variants/${data.sku}`)
  .then((response) => {
    return response.data;
  });
};

const getProductDetails = async function (data) {
  return axiosInstance
  .get(`${baseURL}/products/${data.id}`)
  .then((response) => {
    return response.data;
  });
};

const addToCart = async function (data) {
  let params = data.data;
  return axiosInstance
  .post(`${baseURL}/variants/${params.variant_id}/add_to_cart?quantity=${params.quantity}`)
  .then((response) => {
    return response.data;
  });
};

const removeItemFromCart = async function (data) {
  return axiosInstance
  .delete(`${baseURL}/cart_items/${data.cart_item_id}`)
  .then((response) => {
    return response.data;
  });
};

const getCartDetails = async function (data) {
  return axiosInstance
  .get(`${baseURL}/carts/user_active_cart`)
  .then((response) => {
    return response.data;
  });
};

const createCart = async function (data) {
  return axiosInstance
  .post(`${baseURL}/carts`, { ...data })
  .then((response) => {
    return response.data;
  });
};

const checkoutCart = async function (data) {
  return axiosInstance
  .post(`${baseURL}/carts/${data.cart_id}/checkout?shipping_address_id=${data.address_id}`)
  .then((response) => {
    return response.data;
  });
};

const checkPaymentStatus = async function (data) {
  return axiosInstance
  .get(`${baseURL}/payments/check_status?transaction_id=${data.transaction_id}`)
  .then((response) => {
    return response.data;
  });
};

const getCatalogue = async function (data) {
  return axiosInstance
  .get(`${baseURL}/categories/catalogue`)
  .then((response) => {
    return response.data;
  });
};

const getAddresses = async function (data) {
  return axiosInstance
  .get(`${baseURL}/addresses`)
  .then((response) => {
    return response.data;
  });
};

const createAddress = async function (data) {
  return axiosInstance
  .post(`${baseURL}/addresses`, { ...data.address})
  .then((response) => {
    return response.data;
  });
};

const updateAddress = async function (data) {
  return axiosInstance
  .put(`${baseURL}/addresses/${data.address.id}`, { ...data.address })
  .then((response) => {
    return response.data;
  });
};

const deleteAddress = async function (data) {
  return axiosInstance
  .delete(`${baseURL}/addresses/${data.address_id}`)
  .then((response) => {
    return response.data;
  });
};

const getOrders = async function (data) {
  return axiosInstance
  .get(`${baseURL}/orders?page=${data.page ? data.page : 1}&limit=10`)
  .then((response) => {
    return response.data;
  });
};

const getUserProfile = async function (data) {
  return axiosInstance
  .get(`${baseURL}/users/my_profile`)
  .then((response) => {
    return response.data;
  });
};

const updateUserProfile = async function (data) {
  return axiosInstance
  .patch(`${baseURL}/users/${data.id}`, { ...data.details })
  .then((response) => {
    return response.data;
  });
};

const getWishlist = async function (data) {
  return axiosInstance
  .get(`${baseURL}/wishlists/my_wishlist?wishlist_id=154598e2-3bd6-4ff6-acdc-dfee7dc50009`)
  .then((response) => {
    return response.data;
  });
};

const addToWishlist = async function (data) {
  return axiosInstance
  .post(`${baseURL}/variants/${data.variant_sku}/add_to_wishlist`)
  .then((response) => {
    return response.data;
  });
};

const removeFromWishlist = async function (data) {
  return axiosInstance
  .delete(`${baseURL}/wishlist_items/${data.variant_sku}`)
  .then((response) => {
    return response.data;
  });
};

const getRecommendedProducts = async function (data) {
  return axiosInstance
  .get(`${baseURL}/products?page=${data.page_no}&limit=4`)
  .then((response) => {
    return response.data;
  });
};

const cancelOrder = async function (data) {
  return axiosInstance
  .post(`${baseURL}/orders/${data.order_id}/cancel`)
  .then((response) => {
    return response.data;
  });
};

const optInToNewsletter = async function (data) {
  return axiosInstance
  .post(`${baseURL}/email_subscriptions`, { ...data })
  .then((response) => {
    return response.data;
  });
};

const checkPincode = async function (data) {
  return axiosInstance
  .get(`${baseURL}/pincodes/check_serviceability?pincode=${data}`)
  .then((response) => {
    return response.data;
  });
};


export const ApiService = {
  sendOTP,
  verifyOTP,
  getCategories,
  getSubCategories,
  getProducts,
  getVariant,
  getProductDetails,
  addToCart,
  createCart,
  getCartDetails,
  checkoutCart,
  checkPaymentStatus,
  removeItemFromCart,
  getCatalogue,
  getAddresses,
  createAddress,
  updateAddress,
  deleteAddress,
  getOrders,
  getUserProfile,
  updateUserProfile,
  getWishlist,
  addToWishlist,
  removeFromWishlist,
  getRecommendedProducts,
  cancelOrder,
  optInToNewsletter,
  checkPincode
};
