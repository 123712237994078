import { useState } from "react";
import { Input, Steps, Collapse, Button } from "antd";
import { MailOutlined, TabletOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { LOGIN_TYPES, INPUT_TYPES } from "../../utils/enumData";
import { sendOTP, verifyOTP } from "../../store/actions";

export default function Login() {
  const BLOCK_CLASS = "login";
  const dispatch = useDispatch();
  const [loginType, setLoginType] = useState(LOGIN_TYPES.sms);
  const [isOTPSent, setOTPStatus] = useState(false);
  const [email, setEmail] = useState(null);
  const [phonenumber, setPhoneNumber] = useState(null);
  const [isValidPhonenumber, setIsValidPhoneNumber] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [isValidOTP, setIsValidOTP] = useState(false);
  const [otp, setOTP] = useState(null);

  const onGetOTPClick = () => {
    let data = {
      channel: loginType,
      email: email,
      mobile: phonenumber
    };

    dispatch(sendOTP(data));
    setOTPStatus(true);
  }

  const onVerifyOTPClick = () => {
    let data = {
      channel: loginType,
      email: email,
      mobile: phonenumber,
      otp: otp,
      agent: {
          device_type: "web",
          device_name: "windows",
          ip_address: "127.0.0.1"
      }
  };

    dispatch(verifyOTP(data));
  }

  const validateInput = (target) => {
    if (target.name === INPUT_TYPES.otp) {
      validateOTPInput(target.value);
    } else if (target.name === INPUT_TYPES.email) {
      validateEmailInput(target.value);
    } else if (target.name === INPUT_TYPES.phone) {
      validatePhonenumberInput(target.value);
    }
  }

  const validateOTPInput = (value) => {
    if (isNumber(value) && value.length < 7) {
      setOTP(value);

      if (value.length === 6) {
        setIsValidOTP(true);
      } else {
        setIsValidOTP(false);
      }
    }
  }

  const validateEmailInput = (value) => {
    if (/^\S+@\S+\.\S+$/.test(value)) {
      setIsValidEmail(true);
    } else {
      setIsValidEmail(false);
    }
  }

  const isNumber = (val) => {
    if(val - val == 0) {
      return true;
    } else {
      return false;
    }
  }

  const validatePhonenumberInput = (value) => {
    if (isNumber(value) && value.length < 11) {
      setPhoneNumber(value);

      if (value.length === 10) {
        setIsValidPhoneNumber(true);
      } else {
        setIsValidPhoneNumber(false);
      }
    }
  }

  const isGetOTPEnabled = () => {
    if (loginType === LOGIN_TYPES.email) {
      return isValidEmail;
    } else {
      return isValidPhonenumber;
    }
  }

  return (
    <div className={`${BLOCK_CLASS}`}>
      <p className={`${BLOCK_CLASS}__title`}>Login</p>

      {isOTPSent ? (
        <div className={`${BLOCK_CLASS}__form-wrapper`}>
          <div className={`${BLOCK_CLASS}__otp-input-wrapper`}>
            <Input
              value={otp}
              placeholder="Enter OTP"
              name={INPUT_TYPES.otp}
              onChange={(e) => {
                validateInput(e.target);
              }}
            />
          </div>

          <Button
            className={`${BLOCK_CLASS}__secondary-btn`}
            disabled={!isValidOTP}
            onClick={() => {
              onVerifyOTPClick();
            }}
          >
            Verify OTP
          </Button>

          <Button
            className={`${BLOCK_CLASS}__back-btn`}
            onClick={() => {
              setOTPStatus(false);
            }}
          >
            <ArrowLeftOutlined /> Go Back
          </Button>
        </div>
      ) : (
        <div className={`${BLOCK_CLASS}__form-wrapper`}>
          {loginType == LOGIN_TYPES.sms ? (
            <div className={`${BLOCK_CLASS}__phone-input-wrapper`}>
              <div className={`${BLOCK_CLASS}__countrycode`}>
                <p>+91</p>
              </div>
              <Input
                name={INPUT_TYPES.phone}
                placeholder="Phone number"
                value={phonenumber}
                onChange={(e) => {
                  validateInput(e.target);
                }}
              />
            </div>
          ) : (
            <div className={`${BLOCK_CLASS}__email-input-wrapper`}>
              <Input
                name={INPUT_TYPES.email}
                placeholder="Email address"
                onChange={(e) => {
                  setEmail(e.target.value);
                  validateEmailInput(e.target.value);
                }}
              />
            </div>
          )}

          <Button
            className={`${BLOCK_CLASS}__secondary-btn`}
            disabled={!isGetOTPEnabled()}
            onClick={() => {
              onGetOTPClick();
            }}
          >
            Get OTP
          </Button>
          <p className={`${BLOCK_CLASS}__note`}>
            6 Digit OTP will be sent to your{" "}
            {loginType === LOGIN_TYPES.sms ? "Phone Number" : "Email Address"}
          </p>

          <p className={`${BLOCK_CLASS}__other-option`}>Or</p>

          {loginType == LOGIN_TYPES.sms ? (
            <Button
              className={`${BLOCK_CLASS}__mail-btn`}
              onClick={() => {
                setLoginType(LOGIN_TYPES.email);
              }}
            >
              <MailOutlined /> Mail
            </Button>
          ) : (
            <Button
              className={`${BLOCK_CLASS}__mail-btn`}
              onClick={() => {
                setLoginType(LOGIN_TYPES.sms);
              }}
            >
              <TabletOutlined /> Phone
            </Button>
          )}
        </div>
      )}

      {/* <Button className={`${BLOCK_CLASS}__primary-cta`}>Continue</Button> */}
    </div>
  );
}
