import React, { useEffect } from 'react';
const BLOCK_CLASS = "return";


const Returns = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className='return-outer'>
        <div className={`${BLOCK_CLASS}`}>
            <div className={`${BLOCK_CLASS}__title`}>
                <p>Refund and Returns Policy</p>
            </div>
            <div className={`${BLOCK_CLASS}__inner`}>
            <div className={`${BLOCK_CLASS}__content`}>
              <p>Before considering a return, we invite you to appreciate the unique story behind each Nilam product. Our items are hand block printed by skilled artisans using natural dyes, ensuring every piece is one-of-a-kind. These subtle variations and occasional irregularities are a testament to the traditional, manual processes we cherish and are not defects, but rather, part of the beauty of handmade craftsmanship.</p>
              <p>All orders go through a stringent quality check before shipping. However, in the off chance that you have received a damaged product, please notify us at customercare@nilamind.com within 24 hours of receiving the order.</p>
              <p><b>SHIPPING -</b></p>
              <ul>
                <li>
                50 Rs shipping charges will be charged on Orders Below 1500Rs
                </li>
                <li>
                Product are shipped from our warehouse within 2-3 working days.
                </li>
                <li>
                The order will be delivered with in 10 working days.
                </li>
                <li>
                You will receive order tracking number as soon as we ship your order.
                </li>
              </ul>

              <p>
              <b>EXCHANGE -</b>
              </p>
              <ul>
                <li>
                Processing the order within India takes 2-3 working days and then shipping takes 6 - 10 working days. However these are tentitative timeline, we make sure that you receive your product as soon as possible.
                </li>
                <li>
                We do not offer Refunds and we accept Returns (if there's size issue, you may inform us directly on 8979733011 and we can arrange for return and send as per the revised size)
                </li>
                <li>
                The product bought will only be accepted for exchange if informed within 3 days basis the delivery date/time and to be returned in with tags (without removed), with no damage, stains or marks. The Nilam team holds the right to not accept returns in case the product is used or damaged by the customer.
                </li>
                <li>
                Exchange is only applicable if there's any size issue for clothing category
                </li>
                <li>
                We will refund the amount if the product is faulty or defected 
                </li>
                <li>
                You can track your oders details on clicking track order link 
                </li>
                <li>
                No Returns or Exchanges for Discounted/Sale products
                </li>
                <li>
                Shipping & COD (We do not have COD facility) charges are non-refundable
                </li>
                <li>
                In case of exchange, customers are required to pay Rs. 200 exchange fee for us to book the reverse pick up and send the exchanged piece.
                </li>
                <li>
                Parcel sent to us without a prior email will not be accepted.
                </li>
                <li>
                Please share the package unboxing video/pictures for wrong product / Missing item received.
                </li>
                <li>
                Don’t Handover Product without pick-up slip or SMS Confirmation.
                </li>
              </ul>
              <p><b>CANCELLATION POLICY</b></p>
              <p>Cancellation will only be accepted if your order hasn’t been shipped, ideally customer should cancel the product within 3hrs from the order is placed with us. If later, we will charge Rs. 500 and initiate the pending amount as refund for preorders.</p>
              <p>
              <b>FOR ALL EXCHANGE, PLEASE SHIP TO THE FOLLOWING ADDRESS:</b><br></br>
              </p>
              <p>
              Customer has to self-ship the product if the pin code is out of serviceable area with reverse logistic. (Note - Your courier cost do not exceed above Inr 300 .We request you to self ship the products by "Speed Post" as your courier service.
              </p>
              <p>
              Nilam India<br/>
              E-203 Doon Traflagar, Near IT Park<br/>
              Dehradun - 248001
              </p>
              <p>
              Need more help? <br/>
              Call us @ 8979733011<br/>
              Mon - Sat: 9 am to 6 pm (IST)<br/>
              Write to us at customercare@nilamind.com
              </p>
              </div>
            </div>


        </div>

    </div>
  )
}

export default Returns;