import ImageWrapper from "../../components/Image/ImageWrapper";

const FRESHNESS_CLASS = "freshness";

const Freshness = () => {
  return (
    <section className="freshness-section">
      <div className="container">
        <div className={`${FRESHNESS_CLASS}`}>
          <div className={`${FRESHNESS_CLASS}__title`}>
            <h3>The Comfort of Being You.</h3>
          </div>
          <div className={`${FRESHNESS_CLASS}__info`}>
            <p>
              Dress for yourself, own your presence, and let your inner strength
              and beauty shine. Because, you deserve "Everything!".{" "}
            </p>
          </div>
          <div className={`${FRESHNESS_CLASS}__card`}>
            <div className={`${FRESHNESS_CLASS}__cardlist`}>
              <ImageWrapper
                name="freshness-img1"
                alt="freshness"
                classes={`${FRESHNESS_CLASS}__image`}
              />
            </div>
            <div className={`${FRESHNESS_CLASS}__cardlist`}>
              <ImageWrapper
                name="freshness-img2"
                alt="freshness"
                classes={`${FRESHNESS_CLASS}__image`}
              />
            </div>
            <div className={`${FRESHNESS_CLASS}__cardlist`}>
              <ImageWrapper
                name="freshness-img3"
                alt="freshness"
                classes={`${FRESHNESS_CLASS}__image`}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Freshness;
