

const BLOCK_CLASS = 'header';

function Banner() {
  return (
    <div className={`${BLOCK_CLASS}__banner`}>
      Explore the soulful artistry of our premium handcrafted collection
    </div>
  );
}

export default Banner;
