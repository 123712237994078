import React, { useEffect } from 'react';
const BLOCK_CLASS = "privacy";


const Privacy = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);
  
  return (
    <div className='privacy-outer'>
        <div className={`${BLOCK_CLASS}`}>
            <div className={`${BLOCK_CLASS}__title`}>
                <p>Privacy Policy</p>
            </div>
            <div className={`${BLOCK_CLASS}__inner`}>
            <div className={`${BLOCK_CLASS}__content`}>
              <p>DATE OF EFFECT: This Privacy Policy comes into effect from 1st July. 2024</p>
              <p>This Privacy Policy is intended for all Users of Nilamind.com. Nilam is dedicated to respecting and protecting the privacy of our Users. All information User provides, such as phone number, home address, current location, e-mail addresses or any additional personal information found on the site, will be used solely to support User relationship with Nilam. We strive to develop innovative methods to serve Users even better. Nilam is designed to operate efficiently while keeping user’s privacy in mind. This Privacy Policy outlines the types of personal information that Nilam gathers from its users and takes steps to safeguard it. In order to provide a personalized browsing experience, Nilam may collect information from you, which may include technical or other related information from the device used to access Nilam platforms including without limitation to your current location. By registering or using or visiting Nilam platforms, you explicitly accept, without limitation or qualification, the collection, use and transfer of the personal information provided by you in the manner described in the Terms & Conditions and Privacy Policy. Kindly read the Terms & Conditions and the Privacy Policy carefully as it affects your rights and liabilities under law. If you do not accept the Terms and Conditions and this Privacy Policy, PLEASE DO NOT USE NILAM'S SERVICES.</p>
              <p>Nilam reserves the right to take any rightful legal action against the customer if any fraudulent activity is identified such as multiple usage & abuse of coupon code, wrong claims for orders, fraud return orders, tampering with the package, wrong public review announcements, creating copy of the product, usage of brand properties like (logo, name, designs, etc), Fraud negative comments of social media, bot commenting on social media or worng product before returning etc.</p>
              <p>USER’S CONSENT:</p>
              <p>By using or visiting Nilam platforms, you expressly consent to the Terms and conditions and our Privacy Policy and to Nilam processing of Personal Information for the purposes given under the Terms & Conditions and this Privacy Policy, subject to the local laws, in the following ways such as; to create a personalized account containing your contact information, email-id, address, bank details etc based on information you provide or imported from other sites or applications, or any information provided by third parties; to contact you about any Website updates, informational and service-related communications, including important security updates to inform you of other services available from Nilam or its affiliates, if any. (“Affiliates” means entities that control or are controlled by or are under common control with Nilam); to enable you to provide feedback, and contact Nilam customer service in case of any problem with the services or emergency; to conduct surveys, make promotions, advertise and to provide the results thereof such as success stories etc; to provide information about you and your experience to further promote our websites etc.
                to help your friends, contacts, and potential customers find your profile and connect with you; to allow you to share your experience and information with your connections; to give search engines access to public information; to detect, investigate and prevent activities that may violate our policies and are illegal; to provide certain information that enable users to network, post information on social media such as facebook, twitter, Instagram, linkedin, youtube etc; to generate internal reports about the use of our platforms and many more related uses.
                <br/>You acknowledge that by providing your personal information, you have consented to your information being used as described here. We shall take measures to safeguard your information from unauthorized access or inappropriate use by third parties. However, Nilam has no control over third parties' websites and is not responsible for any sort of misuse or any other liability. You acknowledge that by linking with third party web sites, you are aware of the potential misuse and risk involved.
                <br/>I hereby authorize and give consent to Nilam to send me, either directly or through any third-party service provider, various forms of information, alerts, SMS messages, calls, commercial communications, and other services to the telephone numbers listed by the company. I also understand that these numbers may or may not be registered with the National Do Not Call Registry or listed in the National Customer Preference Register.</p>
              <p>I also confirm that I will not hold ASBL or its third-party service providers liable or file complaints under the Telecom Commercial Communications Customer Preference (TRAI) Regulations, 2010, or any other applicable regulations, including any amendments thereof, as may be applicable from time to time.</p>
              <p>This consent will be automatically renewed every month. If you wish to discontinue this service, please write to us on customercare@nilamind.com</p>
              <p className='service'>COOKIES:</p>
              <p>“Cookies” are pieces of information that may be placed on User’s device by a service provider for the purpose of facilitating and enhancing User communication and interaction. To the extent possible depending upon User’s device, Nilam may use cookies (and similar items such as web beacons, tags, etc.) to customize User experience. Nilam may also use and place cookies on User devices from our third party service providers in connection with the User services, such as an analytics provider that helps User manage and analyze Nilam Services. User may stop or restrict the placement of cookies on certain devices or flush them from User’s browser by adjusting User’s web browser preferences. However, User may still use Nilam Services, but it may interfere with some of its functionality. Cookies and similar items are not used by Nilam, and may automatically retrieve personal information from Users device without User’s knowledge. Cookies are device and browser specific, so if User makes a change to User’s environment Nilam may reinstall a cookie.
              We might utilize session ID cookies to enable certain features of the website, to better understand how you interact with the website and to monitor aggregate usage by Nilam users and web traffic routing on the Website. Unlike persistent cookies, session cookies are deleted from your computer/ device when you log off from the website and close your browser. You can instruct your browser, by changing its options, to stop accepting cookies or to prompt you before accepting a cookie from the website you visit. If you do not accept cookies, however, you may not be able to use all portions of the website or Services. We might also use tracking technologies which may record information such as internet domain and host names; internet protocol (IP) addresses; browser software and operating system types; click stream patterns; dates and times; and location from which the website is accessed. Our use of cookies and other tracking technologies allows us to improve our web site and your web experience. We may also analyze information that does not contain Personal Information for Trends and statistics.</p>
              <p className='service'>
              POLICY TOWARDS CHILDREN:
              </p>
              <p>The Website is not directed to children under the age of 18 years. We do not knowingly collect personally identifiable information from individuals under the age of 18 years. We do not specifically collect information about children without parental consent or supervision. We encourage you to participate in your child’s experience with Nilam. We are not responsible if anyone under the age of 18 years who would come to our site and consume content or do shopping with us. </p>
              <p className='service'>OPT-OUT PROCESS:</p>
              <p>The User may unsubscribe from Nilam advertisement/marketing messages. All unsubscribe or opt-out requests should be sent to us and Nilam, will process the Users request within a reasonable time after receipt. However, Nilam is not responsible for removing Users personal information from the lists of any third party to whom User has shared information at his/her own discretion. The User should contact such third parties directly and if the user does contact, we assume that you have acknowledged all the risks involved.</p>
              <p className='service'>INFORMATION WE COLLECT AND DATA RETENTION:</p>
              <p>We commit to respecting your online privacy and data. We further recognize your need for appropriate protection and management of any personally identifiable information (“Personal Information”) you share with us. The Personal Information is used for such purposes as mentioned in this privacy policy. Nilam shall collect from its User Private Information and Publicly Available information.Private Information:  Users full name, age, mobile number, permanent address, current location, e-mail address, bank details or any other information required for registering and creating and account and thereafter availing Nilam services. We may share your Private Information only as described in this Privacy Policy and related documents. <br/>Publicly-Available Information: The information made available to the public shall include:<br/>Name (as registered).<br/>Contact Information including email address, phone number and residential address.</p>
              <p>
                Nilam does not solicit any information provided by the User; however, if the information is required by any Central/State Authority for whatsoever purpose, information shall be shared by Nilam, without seeking Users prior.
                <br/>Nilam may send User service-related emails (e.g., account verification, changes/ updates to features of the Service, technical and security notices). We collect the following general types of information about you when you visit our Website: personal information, demographic information, behavioral information, and indirect information. In each case, however, it is indicated on the web site whether any personal or demographic information must be provided to use the requested services or not. Demographic information is information that is not unique to you in the sense that it refers to selected population characteristics, such as your ZIP code or postal code, age, preferences, gender, race or ethnicity, occupation etc.
                <br/>We also collect behavioural information regarding how you use our Website, the areas of our site that you visit, what services you access, geo-location, browser and operating system type, domain names, access times and referring Web Site addresses etc. This information is necessary for analyzing the use of resources, troubleshooting problems, preventing fraud, and improving our services.
                <br/>We collect indirect information about the User when you use certain third party services on our Web Site and the collection of such information is considered necessary for that purpose.
                <br/>We collect financial information such as Bank Account details or Credit Card /Debit Card details or other payment related details; solely for the purpose of making payment to the service providers for the services availed by you. We do not collect sensitive personal information like physical and mental or other health conditions or medical records and history; Biometric information such as fingerprints, voice & facial patterns and DNA any other sensitive information which is confidential or sensitive by its nature. We do not store information of users like the password of your email account or bank details. The password of your Nilam account automatically gets saved in the Nilam server when you create an account or register with NIlam.
                <br/>If you, choose to login with Facebook/ Google or similar sites, we import the information that you choose to share with us by installing the application or logging into that platform or Web Site, and we make it part of your account. Because Nilam allows you to share your information we make certain information about you publicly available by default. Such information may, for example, be accessed by everyone on the Internet, including users without accounts, and may appear in public search engine search results.
                <br/>We may personalize the advertisement shown on our websites. In order to provide this personalization, in addition to information we collect about you on our sites, we acquire information (including personal, demographic, behavioural and indirect information) about the User from third parties who provide it to us.
                <br/>We may also ask for your feedback after the services you avail in an effort to deliver the best possible experience in future. We might communicate with you regularly with tips, advice and survey information on using the Website to improve our services. However, we provide you with the opportunity to opt-out of these types of communications. If you choose to receive these types of communications at the time of registration, but later decide to refrain from receiving these communications, you may simply log into your account and edit your account by changing the option to ‘Unsubscribe’.
                <br/>We retain your personal information to further provide you with better services. If you wish to delete or close your account, please contact us via DM on: Instagram or  eamil. An email will be sent to you to confirm that your personal information and bank details have been deleted. A repository copy of an email will be retained only for as long as Nilam reasonably considers necessary for its audit and record purposes. shall also retain logs, demographic, indirect, and statistical information that may relate to you but do not identify you personally. If your personal information was previously accessed by others using our Sites, we are not able to delete the information or copies thereof from their systems.
                If you do not want your information to be processed as described by this policy, you may revoke your consent to our Privacy Policy. If you wish to do so, please contact us. However, please note that if you do withdraw your consent, you may not be able to use the relevant services and your account will be deleted. Nilam holds the discretionary right to delete the data after the period of 60 days from the date of deletion of your account. Thereafter, Nilam may either delete your personal information or de-identify it so that it is anonymous and not attributed to your identity. For example, we may retain information to prevent, investigate, or identify possible wrongdoing in connection with the Service or to comply with legal obligations.
              </p>
              <p className='service'>COPYRIGHT:</p>
              <p>Copyright and Intellectual Property of this website are owned by Nilam. All content, information, graphics, design and photography, videography are the property of Nilam. No use of these may be made without prior written consent. We understand however that our images may be used on personal blogs as long as credit is given to Nilam with a link to our blog or website or product.</p>
              <p className='service'>PRODUCT DISCLAIMER:</p>
              <p>Nilam attempts to display all of our products & colours as accurately as possible. All fits, styles, and colours are represented as best as we can. Please see our returns policy if you accidentally choose the wrong colour or size. We are always happy to help you get exactly what you need. Arnhem cannot guarantee that the colours represented or shown on the website exactly matches the actual product. You will rely solely on your own judgement in using the Website for purchasing products.</p>
              <p>Although we make every effort to keep sufficient stock of items listed on our site, occasionally we do sell out of certain products and sizes. If we are out of stock on an item you have ordered, we will notify you via email. Any changes will be reflected in your order total as well as your shipping confirmation.</p>
              </div>
            </div>


        </div>

    </div>
  )
}

export default Privacy;