
/**
 * This component renders Image element.
 *
 * @param {string} type The type of the Menu items - Desktop/Mobile
 * @returns {ReactNode} A React element that renders menu items.
 * 
 * TODO:(ketan) Add provision to pass images as per the viewport.
 */
function ImageWrapper(props) {
  
  let folder = props.folder ? props.folder : 'images';

  function onClickHandler() {
    if (props.onclick !== undefined && props.onclick !== null) {
      props.onclick();
    }
  }

  function getImageSource() {
    if (props.srcType === 'internal') {
      return `${process.env.PUBLIC_URL}../../../assets/${folder}/${props.name}${props.extension ? props.extension : '.png'}`;
    } else if (props.externalUrl) {
      return props.externalUrl;
    } else {
      return `${process.env.PUBLIC_URL}../../../assets/${folder}/${props.name}${props.extension ? props.extension : '.png'}`;
    }
  }

  return (
    <img
      src={getImageSource()}
      alt={props.alt}
      className={props.classes}
      onClick={onClickHandler}
    />
  );
}

export default ImageWrapper;
