import React from 'react';
import SideNavigation from '../../components/SideNavigation/SideNavigation';
import Address from '../../components/Address/Address';
import { useSelector } from 'react-redux';

const BLOCK_CLASS = "account";
const PAGE_CLASS = "manage-addresses";

const ManageAddresses = () => {
  const userDetails = useSelector((state) => state.userDetails);

  return (
    <div className="account-main">
      <div className={`${BLOCK_CLASS}`}>
        <div className={`${BLOCK_CLASS}__title`}>
          <p>Welcome! {userDetails?.first_name ? userDetails?.first_name : "User"}</p>
        </div>
        <div className="profile-page">
          <SideNavigation />
          <div className={`${PAGE_CLASS}`}>
            <Address></Address>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ManageAddresses;