import React from "react";
import ImageWrapper from "../Image/ImageWrapper";
import { NavLink, useNavigate } from "react-router-dom";
import { logout } from "../../store/actions";
import { useDispatch } from 'react-redux';

const BLOCK_CLASS = "side-navigation";

const SideNavigation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logoutUser = () => {
    localStorage.clear();
    dispatch(logout());
    navigate("/");
  };

  return (
    <div className={`${BLOCK_CLASS}`}>
      <ul className={`${BLOCK_CLASS}__navigation`}>
        <li className={`${BLOCK_CLASS}__navlist`}>
          <NavLink to="/profile">
            <span>
              <ImageWrapper name="user-account" alt="user" classes="" />
            </span>
            <span>My Profile</span>
          </NavLink>
        </li>
        <li className={`${BLOCK_CLASS}__navlist`}>
          <NavLink to="/address">
            <span>
              <ImageWrapper name="address" alt="address" classes="" />
            </span>
            <span>Address</span>
          </NavLink>
        </li>
        <li className={`${BLOCK_CLASS}__navlist`}>
          <NavLink to="/orders">
            <span>
              <ImageWrapper name="shopping-bag" alt="shopping-bag" classes="" />
            </span>
            <span>Orders</span>
          </NavLink>
        </li>
        <li className={`${BLOCK_CLASS}__navlist`}>
          <NavLink to="/wishlist">
            <span>
              <ImageWrapper name="wishlist" alt="wishlist" classes="" />
            </span>
            <span>My Wishlist</span>
          </NavLink>
        </li>
        <li className={`${BLOCK_CLASS}__navlist`} onClick={logoutUser}>
          <a href="#">
            {/* <span>
                        <ImageWrapper
                            name="wishlist"
                            alt="wishlist"
                            classes=""
                        />
                    </span> */}
            <span>LOGOUT</span>
          </a>
        </li>
      </ul>
    </div>
  );
};

export default SideNavigation;
