import React, { useState } from "react";
import Banner from "./Banner";
import MenuItems from "./MenuItems";
import ImageWrapper from "../Image/ImageWrapper";
import { useNavigate, NavLink } from "react-router-dom";
import { showDrawer, toggleMobileDrawerMenu, logout, togglePlaceOrderModal, getCartDetails } from "../../store/actions";
import { DRAWER_TYPES } from "../../utils/enumData";
import { useDispatch, useSelector } from "react-redux";
import { Badge } from 'antd';

const BLOCK_CLASS = "header";

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMobileMenuOpen = useSelector((state) => state.isMobileMenuOpen);
  const isUserLoggedIn = useSelector((state) => state.isUserLoggedIn);
  const cartDetails = useSelector((state) => state.cartDetails);
  const openMenu = () => {
    dispatch(toggleMobileDrawerMenu());
  };

  const onCartBtnClick = () => {
    dispatch(showDrawer({type: DRAWER_TYPES.cart}));
    if (isUserLoggedIn) {
      dispatch(getCartDetails());
    }
  }

  const onLoginClick = () => {
    if (isUserLoggedIn) {
      dispatch(toggleMobileDrawerMenu());
      localStorage.clear();
      dispatch(logout());
      navigate("/");
    } else {
      dispatch(toggleMobileDrawerMenu());
      dispatch(togglePlaceOrderModal({ type: 'login' }));
    }
  }

  const onWishlistBtnClick = () => {
    if (isUserLoggedIn) {
      navigate("/wishlist");
    } else {
      dispatch(togglePlaceOrderModal({ type: "login" }));
    }
  }

  return (
    <div className={BLOCK_CLASS}>
      <Banner />
      <div className={`${BLOCK_CLASS}__mobile`}>
        <nav className={`${BLOCK_CLASS}__navbar`}>
          <button onClick={openMenu} className="no-pad ham-btn">
            <ImageWrapper name="hamburger" alt="Open Menu" classes="ham-icon" />
          </button>

          <NavLink to="/">
            <ImageWrapper
              name="logo"
              alt="Nilam Logo"
              classes={`${BLOCK_CLASS}__logo`}
            />
          </NavLink>

          <div className={`${BLOCK_CLASS}__mobile-btn-wrapper`}>
            <button onClick={onWishlistBtnClick}>
              <ImageWrapper
                name="heart"
                alt="Wishlist button"
                classes={`${BLOCK_CLASS}__cart-icon ${BLOCK_CLASS}__cart-icon--mobile`}
              />
            </button>
            <Badge count={cartDetails?.items?.length}>
            <button onClick={onCartBtnClick}>
              <ImageWrapper
                name="shopping-bag"
                alt="Shopping Cart"
                classes={`${BLOCK_CLASS}__cart-icon ${BLOCK_CLASS}__cart-icon--mobile`}
              />
            </button>
            </Badge>
          </div>
        </nav>
        <nav className={`${BLOCK_CLASS}__subnav`}>
          <MenuItems type="desktop" />
        </nav>

        <div
          className={`${BLOCK_CLASS}__drawer-menu ${
            isMobileMenuOpen ? BLOCK_CLASS + "__drawer-menu--visible" : ""
          }`}
        >
          <div className={`${BLOCK_CLASS}__drawer-header`}>
            <button>
              <ImageWrapper
                name="logo"
                alt="Nilam Logo"
                classes={`${BLOCK_CLASS}__logo`}
              />
            </button>

            <button className={`${BLOCK_CLASS}__close-btn`}>
              <ImageWrapper
                name="close-icon"
                alt="Close menu"
                classes={`${BLOCK_CLASS}__close-icon`}
                onclick={openMenu}
              />
            </button>
          </div>

          <MenuItems type="mobile" />
          <ImageWrapper
            name="nav-background"
            alt="Background image"
            classes={`${BLOCK_CLASS}__bg-img`}
          />
          <p className={`${BLOCK_CLASS}__bottom-fixed-item`}>
            <span onClick={onLoginClick}>{ isUserLoggedIn ? 'Logout' : 'Login' }</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Header;
