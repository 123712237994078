import { useDispatch, useSelector } from "react-redux";
import Header from "./components/Header/Header";
import { BrowserRouter } from "react-router-dom";
import { Modal, message, ConfigProvider } from "antd";
import "./scss/globals/styles.scss";
import Footer from "./components/Footer/Footer";
import Cart from "./components/Drawer/Cart";
import { togglePlaceOrderModal, clearToastMessage } from "./store/actions";
import PlaceOrder from "./components/ModalContent/PlaceOrder/PlaceOrder";
import LoginModal from "./components/ModalContent/LoginFlow/loginmodal";
import Root from "./Root";
import SizeChart from "./components/ModalContent/SizeChart/SizeChart";
import { useEffect } from "react";
import Loader from "./components/Loader/Loader";

function App() {
  const [messageApi, contextHolder] = message.useMessage();
  const dispatch = useDispatch();
  const isModalOpen = useSelector((state) => state.isModalVisible);
  const currentModalType = useSelector((state) => state.currentModalType);
  const toastMessage = useSelector((state) => state.toastMessage);
  const isAppLoaderVisibile = useSelector((state) => state.isAppLoaderVisibile);
  const isUserLoggedIn = useSelector((state) => state.isUserLoggedIn);
  const handleCancel = () => {
    dispatch(togglePlaceOrderModal());
  };

  const getWidth = () => {
    if (currentModalType == "login") {
      return 400;
    } else if (currentModalType == "placeorder") {
      return 820;
    } else {
      return 600;
    }
  };

  useEffect(() => {
    if (toastMessage !== undefined && toastMessage !== null) {
      if (toastMessage.success) {
        showSuccessToast();
      } else {
        showErrorToast();
      }
      dispatch(clearToastMessage());
    }
  }, [toastMessage]);

  const showSuccessToast = () => {
    messageApi.open({
      type: "success",
      content: toastMessage.message,
    });
  };

  const showErrorToast = () => {
    const errorMessage =
      toastMessage?.response?.data?.error !== undefined
        ? toastMessage.response.data.error
        : toastMessage.message;

    messageApi.open({
      type: "error",
      content: errorMessage,
    });
  };

  return (
    <ConfigProvider
      theme={{
        token: {
          // Seed Token
          colorPrimary: '#005394',
          borderRadius: 5
        },
      }}
    >
      <div
        className="App"
        style={
          isAppLoaderVisibile ? { overflow: "hidden", height: "100vh" } : {}
        }
      >
        {contextHolder}
        <BrowserRouter>
          <Header />
          <Root />
          <Footer isUserLoggedIn={isUserLoggedIn} />

          <Cart></Cart>
        </BrowserRouter>

        {isAppLoaderVisibile ? <Loader></Loader> : null}

        <Modal
          open={isModalOpen}
          footer={null}
          width={getWidth()}
          onCancel={handleCancel}
        >
          {currentModalType == "login" ? <LoginModal></LoginModal> : null}

          {currentModalType == "placeorder" ? <PlaceOrder></PlaceOrder> : null}

          {currentModalType == "sizechart" ? <SizeChart></SizeChart> : null}
        </Modal>
      </div>
    </ConfigProvider>
  );
}

export default App;
