import React, { useState } from "react";
import { useDispatch, useSelector, connect } from "react-redux";
import { hideDrawer } from "../../store/actions";
import { DRAWER_TYPES } from "../../utils/enumData";
import ImageWrapper from "../Image/ImageWrapper";
import { CaretDownOutlined } from '@ant-design/icons';
import Product from "../../pages/Product/Product";

function CartSummary(props) {
  const BLOCK_CLASS = "cart-summary";
  const CART_CLASS = "cart";
  const dispatch = useDispatch();
  const isCartVisible = useSelector((state) => state.isCartVisible);
  const defaultVisibility = window.innerWidth < 1024 ? false : true;
  const [isSummaryVisible, setVisibility] = useState(defaultVisibility);

  const onClose = () => {
    dispatch(hideDrawer({ type: DRAWER_TYPES.cart }));
  };

  const handleChange = (value) => {
    if (!defaultVisibility) {
      setVisibility(!isSummaryVisible);
    }
  };

  const getTotalPrice = (value) => {
    let totalAmount = 0;
    props.cartDetails?.items.forEach(product => {
      totalAmount = totalAmount + (product.total_price * product.quantity);
    });

    return totalAmount;
  };

  return (
    <div className={`${BLOCK_CLASS}`}>
      <p className={`${BLOCK_CLASS}__title`} onClick={handleChange}>Cart Summary <CaretDownOutlined /></p>
      
      <div className={`${BLOCK_CLASS}__content-wrapper ${isSummaryVisible ? 'active' : ''}`}>
        <div className={`${CART_CLASS}__items-wrapper--summary`}>
          {props.cartDetails?.items?.map((data) => {
            return (
              <div
                className={`${CART_CLASS}__item`}
                key={"summary_" + data.name}
              >
                <ImageWrapper
                  externalUrl={data.thumbnail}
                  name="product image"
                  alt="product image"
                  classes={`${CART_CLASS}__item-image`}
                />
                <div className={`${CART_CLASS}__item-details`}>
                  <h4 className={`${CART_CLASS}__item-label`}>{data.product_name}</h4>
                  <div className={`${CART_CLASS}__item-cta-wrapper`}>
                    {data.variant_properties.size ? (
                      <h4
                        className={`${CART_CLASS}__item-label ${CART_CLASS}__item-label--size`}
                      >
                        Size: {data.variant_properties?.size}
                      </h4>
                    ) : (
                      ""
                    )}
                    <h4 className={`${CART_CLASS}__quantity-wrapper--label`}>
                      Quantity: {data.quantity}
                    </h4>
                  </div>
                  <h4
                    className={`${CART_CLASS}__item-label ${CART_CLASS}__item-label--price`}
                  >
                    Rs. {data.total_price}
                  </h4>
                </div>
              </div>
            );
          })}
        </div>

        <div className={`${BLOCK_CLASS}__cost-content`}>
          <p className={`${BLOCK_CLASS}__price-label ${BLOCK_CLASS}__price-label--with-border`}>
            SUB TOTAL <span>Rs.{props.cartDetails?.total ? props.cartDetails.total : getTotalPrice()}</span>
          </p>
          
          <p className={`${BLOCK_CLASS}__price-label`}>
            SHIPPING <span className={`${BLOCK_CLASS}__shipping-price`}>&nbsp;Free</span> <span className={`${BLOCK_CLASS}__canceled-price`}>Rs. 209</span>
          </p>

          <p className={`${BLOCK_CLASS}__price-label ${BLOCK_CLASS}__price-label--with-border`}>
            TOTAL <small>(incl. taxes)</small> <span>Rs.{props.cartDetails?.total ? props.cartDetails.total : getTotalPrice()}</span>
          </p>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state, ownProps) {
  const { cartItems, cartDetails } = state;
  return { cartItems: cartItems, cartDetails }
}

export default connect(mapStateToProps)(CartSummary);