import Productcard from "../ProductCard/Productcard";
import RecommendCard from "../RecommendCard/Recommendcard";
import ImageWrapper from "../../components/Image/ImageWrapper";
import Quotes from '../../quotes.json';
import { useSelector } from "react-redux";

const BLOCK_CLASS = "recommendations";

function Recommendations(props) {
  const recommendations = useSelector((state) => state.recommendations);

  return (
    <div>
      <div className={`${BLOCK_CLASS}`}>
        {recommendations !== null ? (
          <>
            <RecommendCard products={recommendations}/>
          </>
        ) : null}
      </div>
      <div className="quotes">
        <ImageWrapper
          name={`${
            window.innerWidth < 1024 ? "mobile-separator" : "separator-bg"
          }`}
          alt="separator"
          classes={`design-separator`}
        />
        <p>{Quotes.quotes[Math.floor(Math.random() * (38 - 1) + 1)]}</p>
      </div>
    </div>
  );
}

export default Recommendations;
