import React, { useEffect, useRef } from "react";
import ImageWrapper from "../../components/Image/ImageWrapper";
import Story from "../../components/Story/Story";
import Freshness from "./Freshness";
import Categories from "./Categories";
import Reminder from "./Reminder";
import Features from "./Features";
import Testimonials from "./Testimonials";
import Newsletter from "./Newsletter";
import Givetime from "./Givetime";

const Home = () => {
  const videoRef = useRef();

  useEffect(()=> {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="home">
      <section className="hero">
        <video
          autoPlay
          muted={true}
          loop
          playsInline
          ref={videoRef}
          poster={`${process.env.PUBLIC_URL}../../../assets/images/poster.jpg`}
        >
          <source
            src={`${process.env.PUBLIC_URL}../../../assets/videos/cover.mp4`}
            type="video/mp4"
          />
        </video>

        <ImageWrapper
          name={`${
            window.innerWidth < 1024 ? "banner-btm-mobile" : "banner-btm-desk"
          }`}
          alt="Clothing-img4"
          classes={`bg-element`}
        />
      </section>
      <Freshness />
      <Categories />
      <Reminder />
      <Features />
      <section className="story-section">
        <Story />
      </section>
      <Testimonials />
      <Givetime />
      <Newsletter />
    </div>
  );
};

export default Home;
