import { Link } from "react-router-dom";
import ImageWrapper from '../Image/ImageWrapper';
const FOOTER_CLASS = "footer";
const LINKS_CLASS = "links";

const Footer = (props) => {
  return (
    <section className="footer-main">
      <div className={`${FOOTER_CLASS}`}>
        <div className="container">
          <div className={`${FOOTER_CLASS}__logo`}>
            <ImageWrapper
              name="footer-logo"
              alt="Nilam Logo"
              classes={`${FOOTER_CLASS}__image`}
            />
          </div>
          <div className={`${FOOTER_CLASS}__content`}>
            <div className={`${FOOTER_CLASS}__content-list`}>
              <p>#FreshnessWornEveryday</p>
              <p>Feel Fresh, Look Your Best: Your Style, Your Story!</p>
            </div>
            <div className={`${FOOTER_CLASS}__content-list`}>
              <span className={`${FOOTER_CLASS}__beat`}>NEVER MISS A BEAT</span>
              <span className={`${FOOTER_CLASS}__separator`}></span>
              <ul className={`${FOOTER_CLASS}__content-item-links`}>
                <li className={`${FOOTER_CLASS}__content-item`}>
                  <a
                    href="https://www.instagram.com/nilamindia?igsh=MTIwaDVobDh4aXJtbw=="
                    target="_blank"
                  >
                    <ImageWrapper name="instagram" alt="Instagram" />
                  </a>
                </li>
                <li className={`${FOOTER_CLASS}__content-item`}>
                  <a
                    href="https://www.linkedin.com/company/102890503"
                    target="_blank"
                  >
                    <ImageWrapper name="linkedin" alt="Linkedin" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="social-links">
            <ul className={`${LINKS_CLASS}`}>
              <li className={`${LINKS_CLASS}__list`}>
                <h5>get to know us</h5>
              </li>
              <li className={`${LINKS_CLASS}__list`}>
                <ul className={`${LINKS_CLASS}__list-inner`}>
                  <li className={`${LINKS_CLASS}__list-items`}>
                    <Link to="/about-us">Who is Nilam? </Link>
                  </li>
                  {/* <li className={`${LINKS_CLASS}__list-items`}>
                                <a href="#">Blogs </a>
                            </li> */}
                  <li className={`${LINKS_CLASS}__list-items`}>
                    <Link to="privacy">Privacy policy</Link>
                  </li>
                  <li className={`${LINKS_CLASS}__list-items`}>
                    <Link to="terms">Terms of use</Link>
                  </li>
                </ul>
              </li>
            </ul>
            <ul className={`${LINKS_CLASS}`}>
              <li className={`${LINKS_CLASS}__list`}>
                <h5>need help?</h5>
              </li>
              <li className={`${LINKS_CLASS}__list`}>
                <ul className={`${LINKS_CLASS}__list-inner`}>
                  <li className={`${LINKS_CLASS}__list-items`}>
                    <Link to="/contact"> Contact us </Link>
                  </li>
                  <li className={`${LINKS_CLASS}__list-items`}>
                    <Link to="faqs">FAQs</Link>
                  </li>
                  <li className={`${LINKS_CLASS}__list-items`}>
                    <Link to="washcare">Wash Care</Link>
                  </li>
                </ul>
              </li>
            </ul>
            <ul className={`${LINKS_CLASS}`}>
              <li className={`${LINKS_CLASS}__list`}>
                <h5>our Collection</h5>
              </li>
              <li className={`${LINKS_CLASS}__list`}>
                <ul className={`${LINKS_CLASS}__list-inner`}>
                  <li className={`${LINKS_CLASS}__list-items`}>
                    <a href="/collection/Clothing">Clothing </a>
                  </li>
                  <li className={`${LINKS_CLASS}__list-items`}>
                    <a href="/products/1766a0ba-4298-4c25-856b-0b058d58b7e6">
                      Bags{" "}
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
            <ul className={`${LINKS_CLASS}`}>
              <li className={`${LINKS_CLASS}__list`}>
                <h5>Orders</h5>
              </li>
              <li className={`${LINKS_CLASS}__list`}>
                <ul className={`${LINKS_CLASS}__list-inner`}>
                  {props.isUserLoggedIn ? (
                    <li className={`${LINKS_CLASS}__list-items`}>
                      <a href="/orders">Orders </a>
                    </li>
                  ) : null}
                  <li className={`${LINKS_CLASS}__list-items`}>
                    <Link to="refund">Refund & Returns Policy</Link>
                  </li>
                  {/* <li className={`${LINKS_CLASS}__list-items`}>
                                <a href="#"> Exchange Policy</a>
                            </li> */}
                </ul>
              </li>
            </ul>
          </div>
          <div className={`${FOOTER_CLASS}__secured-payments`}>
            <ImageWrapper
              name="payments"
              alt="Nilam Logo"
              classes={`${FOOTER_CLASS}__payment-banner`}
            ></ImageWrapper>
          </div>
        </div>
        <div className={`${FOOTER_CLASS}__copyright`}>
          <p className={`${FOOTER_CLASS}__copyright-text`}>
            &#169; 2024, All Rights Reserved with Tres Innovate Private Limited
          </p>
        </div>
      </div>
    </section>
  );
}

export default Footer;