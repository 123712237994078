import { NavLink, useNavigate } from "react-router-dom";
import ImageWrapper from "../Image/ImageWrapper";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showDrawer, togglePlaceOrderModal } from "../../store/actions";
import { Badge } from 'antd';

/**
 * This component renders Menu items for Header for Mobile Drawer & Desktop Nav.
 *
 * @param {string} type The type of the Menu items - Desktop/Mobile
 * @returns {ReactNode} A React element that renders menu items.
 */
function MenuItems(props) {
  const isDesktop = props.type === "desktop";
  const BLOCK_CLASS = isDesktop ? "desktop-subnav" : "mobile-menu";
  const isHidden = isDesktop ? "hidden" : "";
  const womenRef = useRef();
  const menRef = useRef();
  const decorRef = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const refsCollection = [womenRef, decorRef, menRef];

  const isUserLoggedIn = useSelector((state) => state.isUserLoggedIn);
  const catalogue = useSelector((state) => state.catalogue);
  const cartDetails = useSelector((state) => state.cartDetails);

  function onDropdownIconClick(e, name) {
    e.preventDefault();
    
    if (name === "Clothing") {
      if (
        womenRef.current.classList.contains(
          `${BLOCK_CLASS}__dropdown-menu--visible`
        )
      ) {
        womenRef.current.classList.remove(
          `${BLOCK_CLASS}__dropdown-menu--visible`
        );
      } else {
        womenRef.current.classList.add(
          `${BLOCK_CLASS}__dropdown-menu--visible`
        );
      }
    }

    if (name === "Bags") {
      if (
        decorRef.current.classList.contains(
          `${BLOCK_CLASS}__dropdown-menu--visible`
        )
      ) {
        decorRef.current.classList.remove(
          `${BLOCK_CLASS}__dropdown-menu--visible`
        );
      } else {
        decorRef.current.classList.add(
          `${BLOCK_CLASS}__dropdown-menu--visible`
        );
      }
    }
  }

  const handleUserIconClick = () => {
    if (isUserLoggedIn) {
      navigate("/profile");
    } else {
      dispatch(togglePlaceOrderModal({ type: "login" }));
    }
  };

  const onCartBtnClick = () => {
    dispatch(showDrawer({ type: "cart" }));
  };

  const onWishlistBtnClick = () => {
    if (isUserLoggedIn) {
      navigate("/wishlist");
    } else {
      dispatch(togglePlaceOrderModal({ type: "login" }));
    }
  }

  return (
    <>
      <ul className={BLOCK_CLASS}>
        {catalogue !== undefined &&
          catalogue !== null &&
          Object.keys(catalogue?.categories).map((category, i) => {
            let url =
              category == "Clothing"
                ? `collection/Clothing`
                : `/products/1766a0ba-4298-4c25-856b-0b058d58b7e6`;
            return (
              <>
                <NavLink to={url}>
                  <li
                    className={`${BLOCK_CLASS}__list-item`}
                    key={category + " " + i}
                    dropdown-id={category}
                  >
                    <div className={`${BLOCK_CLASS}__item-name`}>
                      <span>{category}</span>
                      {Object.keys(catalogue.categories[category].subcategories)
                        .length > 0 ? (
                        <button
                          onClick={(e) => {
                            onDropdownIconClick(e, category);
                          }}
                          className={`${"caret-btn"}`}
                        >
                          <ImageWrapper
                            name="caret"
                            alt="Open"
                            classes={`dropdown-caret ${BLOCK_CLASS}__caret-icon`}
                          />
                        </button>
                      ) : null}
                    </div>
                    {Object.keys(catalogue?.categories[category]?.subcategories)
                      ?.length > 0 ? (
                      <div
                        className={`${BLOCK_CLASS}__dropdown-menu`}
                        ref={refsCollection[i]}
                      >
                        {Object.keys(
                          catalogue.categories[category].subcategories
                        ).map((subcategory) => {
                          let subcategoryId =
                            catalogue.categories[category].subcategories[
                              subcategory
                            ].id;
                          return (
                            <NavLink
                              to={`products/${category}/${subcategoryId}`}
                              key={subcategory}
                            >
                              {subcategory}
                            </NavLink>
                          );
                        })}
                      </div>
                    ) : (
                      ""
                    )}
                  </li>
                </NavLink>
              </>
            );
          })}

        {isUserLoggedIn ? (
          <>
            <li className={`${BLOCK_CLASS}__list-item ${isHidden} pad-t-60`}>
              <NavLink to="/profile">My Profile</NavLink>
            </li>
            <li className={`${BLOCK_CLASS}__list-item ${isHidden}`}>
              <NavLink to="/address">Address</NavLink>
            </li>
            <li className={`${BLOCK_CLASS}__list-item ${isHidden}`}>
              <NavLink to="/orders">Orders</NavLink>
            </li>
            <li className={`${BLOCK_CLASS}__list-item ${isHidden}`}>
              <NavLink to="/wishlist">My Wishlist</NavLink>
            </li>
          </>
        ) : null}

        <div className={`${BLOCK_CLASS}__desktop-icons`}>
          <button onClick={handleUserIconClick}>
            <ImageWrapper
              name="user-account"
              alt="User account button"
              classes={`${BLOCK_CLASS}__user-icon`}
            />
          </button>
          <button onClick={onWishlistBtnClick}>
            <ImageWrapper
              name="heart"
              alt="Wishlist button"
              classes={`${BLOCK_CLASS}__wishlist-icon`}
            />
          </button>
          <Badge count={cartDetails?.items?.length} size="small" className={`${BLOCK_CLASS}__badge`}>
            <button onClick={onCartBtnClick}>
              <ImageWrapper
                name="shopping-bag"
                alt="Cart button"
                classes={`${BLOCK_CLASS}__cart-icon--desktop`}
              />
            </button>
          </Badge>
        </div>
      </ul>
    </>
  );
}

export default MenuItems;
