import React, { useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { clearOrderState } from "../../store/actions";

const Payment = (props) => {
  const dispatch = useDispatch();
  const [phonePeURL, setPhonePeURL] = useState('');

  const linkRef = useRef();

  useEffect(()=> {
    if (phonePeURL !== '') {
      linkRef.current.referrerPolicy = "unsafe-url";
      linkRef.current.click();
    }
  }, [phonePeURL])

  useEffect(() => {
    if (props.paymentURL !== undefined && props.paymentURL !== null) {
      let redirectUrl = props.paymentURL;
      dispatch(clearOrderState());
      setPhonePeURL(redirectUrl);
    }
  }, []);

  return <div className="payment">
    <a ref={linkRef} href={phonePeURL}>Redirect</a>
  </div>;
};

function mapStateToProps(state, ownProps) {
  const { paymentURL } = state;
  return { paymentURL };
}

export default connect(mapStateToProps)(Payment);
