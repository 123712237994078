import React from "react";
import ImageWrapper from "../../components/Image/ImageWrapper";
const STORY_CLASS = "story";


const Story = () => {
    const readMore = () =>{
        var read = document.getElementById("read-more");
        var moreText = document.getElementById("read-more-text");
        if (read.style.display === "block") {
            moreText.innerHTML = "none";
        } else {
          read.style.display = "none";
          moreText.style.display = "inline";
        }
    }
  return (
    <div className="container">
      <div className={`${STORY_CLASS}`}>
        <div className={`${STORY_CLASS}__title`}>
          <h3>Our</h3>
          <h4>Story</h4>
        </div>
        <div className="our-story">
          <figure className={`${STORY_CLASS}__img`}>
            <ImageWrapper
              name="story-img"
              alt="Our Story"
              classes={`${STORY_CLASS}__icon`}
            />
          </figure>
          <p>
            Our story began in 1973, nestled in a humble printing shed in the
            city of love, Dehradun. With only a handful of passionate souls, we
            embarked on a journey to breathe life into our clothing and home
            decor space. As our designs, colors, and unwavering commitment to
            quality began to resonate, something magical happened.
            <span id="read-more" onClick={readMore}>
              <span className="dots">....</span>{" "}
              <span className="more" id="moretext">
                Read more
              </span>{" "}
            </span>
            <span id="read-more-text">
              {" "}
              People not only accepted our work but embraced it with open
              hearts. The outpouring of love and appreciation from our cherished
              customers touched us deeply, which helped us to create a small
              community. It was in those moments of connection and warmth that
              we realized our purpose: to share Nilam's refreshing world with
              everyone. Every delicate stitch and each meticulously crafted
              block print, we pour our hearts and souls into creating designs
              that not only enhance style but also uplift spirits. Through our
              humble journey of connecting with people, we aspire to share our
              work with the digital world, sharing the joy and freshness that
              Nilam represents. We hope our products and designs bring you joy
              and an opportunity for us to stand by you through tough times,
              making you feel fresh, confident, and happy.
            </span>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Story;