import ImageWrapper from "../Image/ImageWrapper";
import { connect } from 'react-redux';

function Loader(props) {
  const BLOCK_CLASS = "loader";

  return (
    <div className={`${BLOCK_CLASS}`}>
      <ImageWrapper
            name={'fav'}
            alt=""
            classes={`${BLOCK_CLASS}__image`}
            srcType={"internal"}
          ></ImageWrapper>

    </div>
  );
}

function mapStateToProps(state, ownProps) {
  const { product } = state;
  return { product }
}

export default connect(mapStateToProps)(Loader);
