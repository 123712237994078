import React, { useEffect, useState } from 'react';
import SideNavigation from '../../components/SideNavigation/SideNavigation';
import { Input, Radio, DatePicker, Button } from "antd";
import { useDispatch, useSelector } from 'react-redux';
import { toggleMobileDrawerMenu, updateUserDetails } from '../../store/actions';
import dayjs from 'dayjs';

const BLOCK_CLASS = "account";
const PROFILE_CLASS = "profile";

const Profile = () => {
  const dispatch = useDispatch();
  const isMobileMenuOpen = useSelector((state) => state.isMobileMenuOpen);
  const userDetails = useSelector((state) => state.userDetails);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [birthDate, setBirthDate] = useState('');
  const [gender, setGender] = useState('');
  const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY', 'DD-MM-YYYY', 'DD-MM-YY'];

  useEffect(()=> {
    onLoad();
  }, []);

  useEffect(()=> {
    renderExisitingProfileDetails();
  }, [userDetails]);
  
  const renderExisitingProfileDetails = () => {
    if (userDetails !== null) {
      let user = userDetails;

      setFirstName(user.first_name ? user.first_name : '');
      setLastName(user.last_name ? user.last_name : '');
      setBirthDate(user.date_of_birth ? user.date_of_birth : '');
      setGender(user.gender ? user.gender : '');
    }
  }

  const onLoad = () => {
    window.scrollTo(0, 0);
    if (isMobileMenuOpen) {
      dispatch(toggleMobileDrawerMenu());
    }
  }

  const submitDetails = () => {
    let data = {
      first_name: firstName,
      last_name: lastName,
      date_of_birth: birthDate,
      gender: gender
    };

    dispatch(updateUserDetails({ id: userDetails.id, details: data }));
  };

  const onDateChange = (e) => {
    let dateString = new Date(e).getDate();
    let monthString = new Date(e).getMonth();
    let yearString = new Date(e).getFullYear();

    setBirthDate(
      `${dateString < 10 ? "0" + dateString : dateString}/${
        (monthString + 1) < 10 ? '0'+(monthString+1) : (monthString + 1) 
      }/${yearString < 10 ? '0'+yearString : yearString}`
    );
  }
  
  return (
    <div className="account-main">
      <div className={`${BLOCK_CLASS}`}>
        <div className={`${BLOCK_CLASS}__title`}>
          <p>
            Welcome!{" "}
            {userDetails?.first_name ? userDetails?.first_name : "User"}
          </p>
        </div>
        <div className="profile-page">
          <SideNavigation />
          <div className={`${PROFILE_CLASS}`}>
            <form className={`${PROFILE_CLASS}__form`}>
              <div className={`${PROFILE_CLASS}__form-list`}>
                <label className={`${PROFILE_CLASS}__label`}>First Name</label>
                <Input
                  className={`${PROFILE_CLASS}__input`}
                  type="text"
                  value={firstName}
                  onChange={(e)=> { setFirstName(e.target.value) }}
                />
              </div>
              <div className={`${PROFILE_CLASS}__form-list`}>
                <label className={`${PROFILE_CLASS}__label`}>Last Name</label>
                <Input
                  className={`${PROFILE_CLASS}__input`}
                  type="text"
                  value={lastName}
                  onChange={(e)=> { setLastName(e.target.value) }}
                />
              </div>
              {userDetails?.email !== null ? (
                <div className={`${PROFILE_CLASS}__form-list`}>
                  <label className={`${PROFILE_CLASS}__label`}>Email</label>
                  <Input
                    className={`${PROFILE_CLASS}__input`}
                    type="text"
                    value={userDetails?.email}
                    disabled
                  />
                </div>
              ) : null}
              {userDetails?.mobile ? (
                <div className={`${PROFILE_CLASS}__form-list`}>
                  <label className={`${PROFILE_CLASS}__label`}>
                    Contact Number
                  </label>
                  <Input
                    className={`${PROFILE_CLASS}__input`}
                    type="text"
                    value={userDetails?.mobile}
                    disabled
                  />
                </div>
              ) : null}
              <div className={`${PROFILE_CLASS}__form-list`}>
                <label className={`${PROFILE_CLASS}__label`}>
                  Date of Birth
                </label>
                {/* <Input
                  className={`${PROFILE_CLASS}__input__input-birth`}
                  type="text"
                />
                <Input
                  className={`${PROFILE_CLASS}__input__input-birth`}
                  type="text"
                />
                <Input
                  className={`${PROFILE_CLASS}__input__input-birth`}
                  type="text"
                /> */}
                <DatePicker onChange={onDateChange} className={`${PROFILE_CLASS}__input`} value={birthDate != '' ? dayjs(birthDate, dateFormatList[0]) : ''} format={dateFormatList}/>
              </div>
              <div className={`${PROFILE_CLASS}__form-list`}>
                <label className={`${PROFILE_CLASS}__label__gender-label`}>
                  Gender
                </label>
                <div className="gender">
                  <Radio.Group className="gender-radio" onChange={(e)=> { setGender(e.target.value) }} value={gender}>
                    <Radio className="input-radio" value="male">
                      Male
                    </Radio>
                    <Radio className="input-radio" value="female">
                      Female
                    </Radio>
                  </Radio.Group>
                </div>
              </div>
              <div className="profile-btn">
                <Button className="save-btn btn" onClick={submitDetails}>Save</Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;